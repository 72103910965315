<template>
    <modal id="customer-modal" :hide-footer="selectedUrl" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('customer')" size="extra-large" @submit="submitData">
        <form ref="form" :data-url='SALES_CUSTOMERS' @submit.prevent="submitData">

            <h5 class="border-bottom mb-4 pb-2">{{ $t('personal_information') }}</h5>
            <div class="row">
                <div class="col-12 col-sm-2 col-md-2">
                    <app-form-group :class="'required'" type="select" :list="Prefix" list-value-field="name"
                        :label="$t('Prefix')" :required="true" :error-message="$errorMessage(errors, 'prefix')"
                        :placeholder="$placeholder('Prefix')" v-model="formData.prefix" />
                </div>
                <div class="col-12 col-sm-4 col-md-4">
                    <app-form-group :label="$t('first_name')" :class="'required'" v-model="formData.first_name"
                        :error-message="$errorMessage(errors, 'first_name')" :placeholder="$placeholder($t('first_name'))"
                        :required="true">
                    </app-form-group>
                </div>
                <div class="col-12 col-sm-4 col-md-4">
                    <app-form-group v-model="formData.last_name" :error-message="$errorMessage(errors, 'last_name')"
                        :label="$t('last_name')" :placeholder="$placeholder($t('last_name'))" />
                </div>
                <div class="customer-group-input mb-3 col-md-2">
                    <label>{{ $t('customer_group') }}</label>
                    <app-input class="margin-right-8" type="search-and-select" :key="customerGroupInputKey"
                        :placeholder="$t('search_and_select', { name: $t('customer_group').toLowerCase() })"
                        :options="customerGroupOptions" :inputclearable="false" v-model="formData.customer_group_id"
                        :error-message="$errorMessage(errors, 'customer_group_id')" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group v-model="formData.record_created_by"
                        :error-message="$errorMessage(errors, 'record_created_by')" :label="$t('Record created by')"
                        :placeholder="$placeholder($t('Record created by'))" disabled />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group type="date" v-model="formData.registration_date"
                        :error-message="$errorMessage(errors, 'registration_date')" :label="$t('Registration Date')"
                        :placeholder="$placeholder($t('Registration Date'))" :readOnly="true" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 required">
                    <label>{{ $t('Original Branch') }}</label>
                    <div class="form-group">
                        <select id="original_branch"
                            class="original_branch form-control"
                            name="original_branch" v-model="formData.original_branch" style="width: 100%;">
                            <option v-for="branch in branchList" :key="branch.id" :value="branch.id">
                                {{ branch.value }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'original_branch')">{{ $errorMessage(errors,
                            'original_branch')
                        }}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-2">
                    <app-form-group :label="$t('Age')" v-model="formData.age"
                        :error-message="$errorMessage(errors, 'age')" :placeholder="$placeholder($t('Age'))" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 required">
                    <label>{{ $t('Gender') }}</label>
                    <div class="app-radio-group">
                        <!-- Male Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Male'"
                                :checked="formData.gender === 'male'" @change="onGenderChange('male')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('male') }}
                        </label>

                        <!-- Female Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Female'"
                                :checked="formData.gender === 'female'" @change="onGenderChange('female')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('female') }}
                        </label>

                        <!-- Other Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Other'"
                                :checked="formData.gender === 'other'" @change="onGenderChange('other')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('other') }}
                        </label>
                    </div>
                    <div v-if="!formData.gender">
                        <small class="text-danger validation-error">
                            <!-- The gender field is required. -->
                            {{ $errorMessage(errors, 'gender') }}
                        </small>
                    </div>
                </div>
            </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group mb-4">
                <label>{{ $t('email_s') }}</label>

                <div v-for="(rowData, index) in inputEmailRow" :key="index" class="row mb-2">
                    <div class="col-md-11">
                        <app-input v-model="rowData.email" :id="'email' + index"
                            :error-message="$errorMessage(errors, 'email_details.' + index + '.email')"
                            :placeholder="$placeholder($t('email'))" />
                    </div>
                    <div class="col-md-1">
                        <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color btn-block"
                            type="button" @click="appendEmailRow">
                            <app-icon class="size-19 primary-text-color" name="plus" />
                        </button>
                        <button v-else :key="'trash' + index" class="btn default-base-color btn-block" type="button"
                            @click="removeEmailRow(index)">
                            <app-icon class="size-19 primary-text-color" name="trash" />
                        </button>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-4">
                <label>{{ $t('phone_number_s') }}</label>
                <div v-for="(rowData, index) in inputPhoneNumberRow" :key="index" class="row mb-2">
                    <div class="col-md-11">
                        <app-input v-model="rowData.phone_number" type="number" :id="'phone_number' + index"
                            :error-message="$errorMessage(errors, 'phone_number_details.' + index + '.phone_number')"
                            :placeholder="$placeholder($t('phone_number'))" />
                    </div>
                    <div class="col-md-1">
                        <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color btn-block"
                            type="button" @click="appendPhoneNumberRow">
                            <app-icon class="size-19 primary-text-color" name="plus" />
                        </button>
                        <button v-else :key="'trash' + index" class="btn default-base-color btn-block" type="button"
                            @click="removePhoneNumberRow(index)">
                            <app-icon class="size-19 primary-text-color" name="trash" />
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
            

            

            <h5 class="border-bottom mb-4 pb-2">{{ $t('address') }}</h5>
            <div v-for="(rowData, index) in inputAddressRow" class="mb-2">
                <div class="row mb-3">
                    <div class="col-md-12">
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group :label="$t('Address Title')" type="radio" :list="[
                                { id: 'home', value: $t('home') },
                                { id: 'work', value: $t('work') },
                                { id: 'other', value: $t('other') }
                            ]" v-model="inputAddressRow[index].name" :id="'title' + index"
                                :error-message="$errorMessage(errors, 'address_information_details.' + index + '.name')" />
                        </div>

                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <app-input v-model="inputAddressRow[index].details" :id="'details' + index"
                            :error-message="$errorMessage(errors, 'address_information_details.' + index + '.details')"
                            :placeholder="$placeholder($t('address_details'))" type="textarea" />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <label>{{ $t('country') }}</label>
                        <app-input class="margin-right-8" type="select" :list="countryList" :key="countryList.length"
                            list-value-field="name" v-model="inputAddressRow[index].country_id"
                            :error-message="$errorMessage(errors, 'country')" />
                    </div>
                    <div class="col-md-6">
                        <label>{{ $t('City') }}</label>
                        <app-input v-model="inputAddressRow[index].city" type="text" :id="'city' + index"
                            :error-message="$errorMessage(errors, 'city')" :placeholder="$placeholder($t('city'))" />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <label>{{ $t('area') }}</label>
                        <app-input v-model="inputAddressRow[index].area" type="text" :id="'area' + index"
                            :error-message="$errorMessage(errors, 'area')" :placeholder="$placeholder($t('area'))" />
                    </div>

                    <div class="col-md-6">
                        <label>{{ $t('zip_code') }}</label>
                        <app-input v-model="inputAddressRow[index].zip_code" type="number" :id="'zip_code' + index"
                            :error-message="$errorMessage(errors, 'zip_code')"
                            :placeholder="$placeholder($t('zip_code'))" />
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { LINKED_CUSTOMERS, WEIGHT_UNIT, GET_CUSTOMERS_NAME } from "../../../Config/ApiUrl-CP";
import { CUSTOMERS,SALES_CUSTOMERS, SELECTABLE_CUSTOMER_GROUP, ATTACHMENTS } from "../../../Config/ApiUrl-CPB";
import { COUNTRIES } from "../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { numberToString } from "../../../../common/Helper/Support/TextHelper";
import { axiosGet, axiosPost, urlGenerator } from "../../../../common/Helper/AxiosHelper";
import { mapGetters,mapMutations,mapActions } from 'vuex';

export default {
    name: 'CustomerCreateEditSalesModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            selectedLinkedPatientsNames: [],
            weightUnit: '',
            attachments: [],
            userList: [],
            branchList: [],
            referredBy: [],
            campList: [],
            attachmentDescriptions: {},
            numberToString,
            customerGroupInputKey: 0,
            customerGroupOptions: {
                url: urlGenerator("app/selectable-customer-groups"),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: false,
            },
            formData: {
                customer_group_id: 2,
                from_sales: 1,
                addresses: {},
                contacts: {},
                tin: '',
                tenant_id: '',
                gender: '',
                satisfaction_status: 'N/A',
                title: '',
                original_branch: '',
                referred_by: '',
                camp_name: '',
                record_created_by: window.user.full_name || '',
                registration_date: '', // Added registration_date property
                addToReport: false,
                first_assigned_to: window.user.id || '',
                currently_assigned_to: window.user.id || '',
            },
            branchOrWareHoueeOptions: {
                url: urlGenerator("app/selectable/branches"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.name, type: value.type }),
            },
            Prefix: [
                { id: '', name: this.$t('Select Prefix') },
                { id: 'Mr.', name: this.$t('Mr.') },
                { id: 'Ms.', name: this.$t('Ms.') },
                { id: 'Mrs.', name: this.$t('Mrs.') },
                { id: 'Dr.', name: this.$t('Dr.') },
            ],
            CurrentUser: [
                { id: '', name: this.$t('Select User') },
                { id: window.user.full_name || '', name: this.$t(window.user.full_name || '') },
            ],
            linkedPatientsList: [],
            maxSelections: 3,
            userOptions: {
                url: urlGenerator("app/selectable/users"),
                queryName: 'search_by_name',
                per_page: 50,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            },
            CUSTOMERS,
            SALES_CUSTOMERS,
            SELECTABLE_CUSTOMER_GROUP,
            COUNTRIES,
            typeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'home', name: this.$t('home') },
                { id: 'work', name: this.$t('work') },
                { id: 'office', name: this.$t('office') }
            ],
            countryList: [],
            inputAddressRow: [],
            index: 0,
            inputEmailRow: [],
            removableEmailRow: true,
            inputPhoneNumberRow: [],
            removablePhoneNumberRow: true,
            inputAddressRow: [
                {
                    name: "",
                    city: "",
                    country_id: "",
                    zip_code: "",
                    area: "",
                    details: "",
                }
            ],
            removableAddressRow: true,
        }
    },
    created() {
        this.getCountries();
        // if (!this.selectedUrl) this.setDefaultGroupId();
        this.branchListOptions();
    },
    watch: {
        inputEmailRow() {
            this.removableEmailRow = this.inputEmailRow.length <= 1;
        },
        inputPhoneNumberRow() {
            this.removablePhoneNumberRow = this.inputPhoneNumberRow.length <= 1;
        },

        'formData.date_of_birth'(newDateOfBirth) {
            if (newDateOfBirth) {
                const today = new Date();
                const birthDate = new Date(newDateOfBirth);
                const age = today.getFullYear() - birthDate.getFullYear();

                // Adjust age based on month and day of birth
                if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                    this.formData.age = age - 1; // Update the age property directly
                } else {
                    this.formData.age = age; // Update the age property directly
                }
            }
        },

        // 'getUserId': {
        //     immediate: true,
        //     handler(newId) {
        //         if (!newId) return;
        //         this.formData.first_assigned_to = newId;
        //         this.formData.currently_assigned_to = newId;
        //         this.ssKey++;
        //         console.log(this.formData.currently_assigned_to);
        //     }
        // },

        'getBranchOrWarehouseId': {
            immediate: true,
            handler(newId) {
                if (!newId) return;
                this.formData.original_branch = newId;
                this.ssKey++;
            }
        }
    },
    mounted() {
        this.appendEmailRow();
        this.appendPhoneNumberRow();
        this.formData.tenant_id = this.getTenantId;
        this.setInitialRegistrationDate(); // Set initial value of registration_date

        // Initialize Select2
        let self = this;
        $('.original_branch').select2({ placeholder: 'Select a user',dropdownParent: $("#customer-modal") }).on("change", function () {
            self.onSelectChangeOriginalBranch(this.value);
        }); 
    },
    computed: {
        ...mapGetters(["getTenantId"]),
        ...mapGetters(['getBranchOrWarehouseName']),
        ...mapGetters(['getBranchOrWarehouseId']),
        ...mapGetters(['getUserId']),
    },
    methods: {
        ...mapMutations(['SET_SELECTED_CUSTOMER']),
        setSelectedCustomer(custId) {
            this.SET_SELECTED_CUSTOMER(custId);
        },
        onSelectChangeOriginalBranch(val) {
            this.formData.original_branch = val;
        },
        branchListOptions() {
            axiosGet(`app/selectable/branches`).then(response => {
                for (const item of response.data.data) {
                    this.branchList.push({
                        id: item.id,
                        value: item.name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        onGenderChange(selectedGender) {
            // Access the selected gender
            this.formData.gender = selectedGender;

            // Perform any action based on the selected gender
            console.log(`Selected gender: ${selectedGender}`);
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1; // Note: month is zero-based
            let day = today.getDate();

            // Pad month and day with leading zeroes if necessary
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${year}-${month}-${day}`;
        },

        setInitialRegistrationDate() {
            this.formData.registration_date = this.getCurrentDate(); // Set initial value of registration_date
        },
        getCountries() {
            axiosGet(COUNTRIES)
                .then(data => {
                    this.countryList = [{ name: this.$t('select_country') }, ...data.data];

                    // Find the ID of the country "India" in the countryList
                    const indiaCountry = this.countryList.find(country => country.name === 'India');
                    if (indiaCountry) {
                        // Set the country_id for India
                        this.inputAddressRow[this.index].country_id = indiaCountry.id;
                    } else {
                        console.log('India not found in country list.');
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            this.formData.first_assigned_to = $($(".first_assigned_to")[0]).val();
            this.formData.currently_assigned_to = $($(".currently_assigned_to")[0]).val();
            this.formData.original_branch = $($(".original_branch")[0]).val();
            this.formData.referred_by = $($(".referredby")[0]).val();
            this.formData.camp_name = $($(".campname")[0]).val();



            this.formData.email_details = this.inputEmailRow.map((item) => ({
                'email': item.email,
                'email_type': item.email_type,
            })
            );
            this.formData.email = this.formData.email_details[0].email;

            this.formData.phone_number_details = this.inputPhoneNumberRow.map((item) => ({
                'phone_number': item.phone_number,
                'phone_number_type': item.phone_number_type,
            })
            );

            const existingAddresses = this.formData.addresses.length ? this.formData.addresses.filter(address => address.id !== this.inputAddressRow[0].id) : [];

            this.formData.address_information_details =
                [...existingAddresses, ...this.inputAddressRow].map((item) => ({
                    'name': item.name,
                    'country_id': item.country_id,
                    'city': item.city,
                    'zip_code': item.zip_code,
                    'area': item.area,
                    'details': item.details,
                })
                );

            this.formData.branch_id = this.getBranchOrWarehouseId;
            // console.log(this.formData.branch_id);

            this.save(this.formData);
        },
        afterSuccess({ data }) {
            if(data.customerId != '') this.setSelectedCustomer(data.customerId);
            this.formData = {};
            this.$emit('afterSubmitSuccess');
            $('#customer-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'customer-table');
            setTimeout(() => {
                window.location.reload();
            }, 500);
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
            if (this.formData.addresses.length) this.inputAddressRow = [this.formData.addresses[0]];
            if (this.formData.contacts.email.length) this.inputEmailRow = this.formData.contacts.email.map((item) => ({
                email: item.value,
                email_type: item.type
            })
            );
            if (this.formData.contacts.phone_number.length) this.inputPhoneNumberRow = this.formData.contacts.phone_number.map((item) => ({
                phone_number: item.value,
                phone_number_type: item.type
            })
            );
        },
        setDefaultGroupId() {
            this.axiosGet(SELECTABLE_CUSTOMER_GROUP).then((response) => {
                let id;
                response.data.filter((item) => {
                    if (+item.is_default === 1) id = item.id;
                })
                this.formData.customer_group_id = id;
                this.customerGroupInputKey++;
            })
        },
        appendEmailRow() {
            let checkEmptyRows = this.inputEmailRow.filter(line => line.number === null);
            if (checkEmptyRows.length >= 1 && this.inputEmailRow.length > 0) return;
            this.inputEmailRow.push({});
        },
        removeEmailRow(rowId) {
            if (!this.removableEmailRow) this.inputEmailRow.splice(rowId, 1);
        },
        appendPhoneNumberRow() {
            let checkEmptyRows = this.inputPhoneNumberRow.filter(line => line.number === null);
            if (checkEmptyRows.length >= 1 && this.inputPhoneNumberRow.length > 0) return;
            this.inputPhoneNumberRow.push({});
        },
        removePhoneNumberRow(rowId) {
            if (!this.removablePhoneNumberRow) this.inputPhoneNumberRow.splice(rowId, 1);
        },
        removeAddressRow(rowId) {
            if (!this.removableAddressRow) this.inputAddressRow.splice(rowId, 1);
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}


/* Increase the width of the Select2 input */
.select2-container--default .select2-selection--single {
    width: 100%;
    border: 1px solid #ced4da;
}

/* Increase the height of the Select2 input */
.select2-container--default .select2-selection--single {
    height: 40px;
}

/* Increase padding and font size */
.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 5px 10px;
    font-size: 16px;
}
</style>