<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$fieldTitle('users_roles')" icon="user-check">
            <app-default-button btn-class="btn btn-success mr-2"
                                :title="$fieldTitle('Add', 'user', true)"
                                @click="isInviterOpenModalActive = true"
                                v-if="$can('invite_user')"
            />

        </app-page-top-section>

        <!--Users And Roles Pages Started Here....-->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <app-users
                    :alias="alias"
                    :roleList="roleList"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <app-roles
                    :alias="alias"
                    @selectedUrl="openEditModal"
                    v-model="isRoleModalActive"
                />
            </div>
        </div>
        <app-user-invite-modal
            :alias="alias"
            v-if="isInviterOpenModalActive"
            v-model="isInviterOpenModalActive"
            :roles="roleList"
        />
        
    </div>
</template>

<script>

import {axiosGet} from "../../Helper/AxiosHelper";
import {ROLES, TENANT_SELECTABLE_ROLES} from "../../Config/apiUrl";

export default {
    props: {
        alias: {
            require: true,
            default: 'app'
        }
    },
    components: {
        'app-users': require('./Users').default,
        'app-roles': require('./Roles').default,
    },
    data() {
        return {
            isInviterOpenModalActive: false,
            isRoleModalActive: false,
            selectedUrl: '',
            roleList: []
        }
    },
    methods: {
        openEditModal(url) {
            this.selectedUrl = url;
            this.isRoleModalActive = true
        },
        getAllRoles() {
            axiosGet(this.getRolesURL)
                .then(({data}) => this.roleList = data)
        }
    },
    computed: {
        getRolesURL() {
            return {
                tenant: TENANT_SELECTABLE_ROLES,
                app: ROLES,
            }[this.alias]
        }
    },
    mounted() {
        this.getAllRoles();
        this.$hub.$on('rolesAffected', () => this.getAllRoles())
    }
}
</script>
