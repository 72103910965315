<template>
    <modal id="customer-modal" :hide-footer="selectedUrl" v-model="showModal" :loading="loading" :preloader="preloader"
        :title="generateModalTitle('customer')" size="extra-large" @submit="submitData">
        <form ref="form" :data-url='selectedUrl ? selectedUrl : CUSTOMERS' @submit.prevent="submitData">

            <h5 class="border-bottom mb-4 pb-2">{{ $t('personal_information') }}</h5>
            <div class="row">
                <div class="col-12 col-sm-2 col-md-2">
                    <app-form-group :class="'required'" type="select" :list="Prefix" list-value-field="name"
                        :label="$t('Prefix')" :required="true" :error-message="$errorMessage(errors, 'prefix')"
                        :placeholder="$placeholder('Prefix')" v-model="formData.prefix" />
                </div>
                <div class="col-12 col-sm-4 col-md-4">
                    <app-form-group :label="$t('first_name')" :class="'required'" v-model="formData.first_name"
                        :error-message="$errorMessage(errors, 'first_name')" :placeholder="$placeholder($t('first_name'))"
                        :required="true">
                    </app-form-group>
                </div>
                <div class="col-12 col-sm-4 col-md-4">
                    <app-form-group v-model="formData.last_name" :error-message="$errorMessage(errors, 'last_name')"
                        :label="$t('last_name')" :placeholder="$placeholder($t('last_name'))" />
                </div>
                <div class="customer-group-input mb-3 col-md-2">
                    <label>{{ $t('customer_group') }}</label>
                    <app-input class="margin-right-8" type="search-and-select" :key="customerGroupInputKey"
                        :placeholder="$t('search_and_select', { name: $t('customer_group').toLowerCase() })"
                        :options="customerGroupOptions" :inputclearable="false" v-model="formData.customer_group_id"
                        :error-message="$errorMessage(errors, 'customer_group_id')" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group v-model="formData.record_created_by"
                        :error-message="$errorMessage(errors, 'record_created_by')" :label="$t('Record created by')"
                        :placeholder="$placeholder($t('Record created by'))" disabled />
                </div>
                <div class="form-element col-12 col-md-4 mb-4">
                    <label>{{ $t('First assigned to') }}</label>
                    <div class="form-group">
                        <select id="first_assigned_to"
                            class="first_assigned_to form-control"
                            name="first_assigned_to" v-model="formData.first_assigned_to" style="width: 100%;">
                            <!--  <option value="">Select a user</option> Add a default empty option -->
                            <option v-for="user in userList" :key="user.id" :value="user.id">
                                {{ user.value }}
                            </option>
                        </select>
                    </div>
                    <!-- <app-input  class="margin-right-8" type="search-and-select" :inputclearable="false"
                        :placeholder="$t('Select First assigned to')" :options="userOptions"
                        v-model="formData.first_assigned_to" :error-message="$errorMessage(errors, 'first_assigned_to')" /> -->
                </div>
                <div class="form-element col-12 col-md-4 mb-4">
                    <label>{{ $t('Currently assigned to') }}</label>
                    <div class="form-group">
                        <select id="currently_assigned_to"
                            class="currently_assigned_to form-control"
                            name="currently_assigned_to" v-model="formData.currently_assigned_to" style="width: 100%;">
                            <!--  <option value="">Select a user</option> Add a default empty option -->
                            <option v-for="user in userList" :key="user.id" :value="user.id">
                                {{ user.value }}
                            </option>
                        </select>
                    </div>
                    <!-- <label>{{ $t('Currently assigned to') }}</label>
                    <app-input  class="margin-right-8" type="search-and-select"
                        :inputclearable="false" :placeholder="$t('Select Currently assigned to')" :options="userOptions"
                        v-model="formData.currently_assigned_to"
                        :error-message="$errorMessage(errors, 'currently_assigned_to')" /> -->
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group type="date" v-model="formData.registration_date"
                        :error-message="$errorMessage(errors, 'registration_date')" :label="$t('Registration Date')"
                        :placeholder="$placeholder($t('Registration Date'))" :readOnly="true" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group type="select" :list="ExaminationVenue" list-value-field="name" :page="'modal'"
                        :label="$t('Examination Venue')" :required="true"
                        :error-message="$errorMessage(errors, 'examination_venue')"
                        :placeholder="$placeholder('Examination Venue')" v-model="formData.examination_venue" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 required">
                    <label>{{ $t('Original Branch') }}</label>
                    <div class="form-group">
                        <select id="original_branch"
                            class="original_branch form-control"
                            name="original_branch" v-model="formData.original_branch" style="width: 100%;">
                            <option v-for="branch in branchList" :key="branch.id" :value="branch.id">
                                {{ branch.value }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'original_branch')">{{ $errorMessage(errors,
                            'original_branch')
                        }}</small>
                    </div>
                    <!-- <label>{{ $t('Original Branch') }}</label>
                    <app-input  class="margin-right-8" type="warehouse-or-branch"
                        :placeholder="$t('Select Original Branch')" :inputclearable="false" :key="ssKey"
                        :options="branchOrWareHoueeOptions" v-model="formData.original_branch"
                        :error-message="$errorMessage(errors, 'original_branch')" /> -->

                    <!-- <app-form-group  v-model="formData.original_branch"
                        :error-message="$errorMessage(errors, 'original_branch')" :label="$t('Original Branch')"
                        :placeholder="$placeholder($t('Original Branch'))" /> -->
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group type="select" :list="LegacyPatient" list-value-field="name" :page="'modal'"
                        :label="$t('Legacy Patient')" :required="true"
                        :error-message="$errorMessage(errors, 'legacy_patient')"
                        :placeholder="$placeholder('Legacy Patient')" v-model="formData.legacy_patient" />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-form-group :label="$t('Guardian name & relationship')"
                        v-model="formData.guardian_name_relationship"
                        :error-message="$errorMessage(errors, 'guardian_name_relationship')"
                        :placeholder="$placeholder($t('Guardian name & relationship'))" :required="true">
                    </app-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <label>{{ $t('Linked patients') }}</label>
                    <app-input type="multi-select" :placeholder="$t('Select Linked patients')" :inputclearable="false"
                        :list="linkedPatientsList" v-model="formData.linked_patients"
                        :error-message="$errorMessage(errors, 'linked_patients')" @input="handleLinkedPatientsChange" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="date" :label="$t('Date of birth')" v-model="formData.date_of_birth"
                        :error-message="$errorMessage(errors, 'date_of_birth')"
                        :placeholder="$placeholder($t('Date of birth'))" />
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <app-form-group :label="$t('Age')" :class="'required'" v-model="formData.age"
                        :error-message="$errorMessage(errors, 'age')" :placeholder="$placeholder($t('Age'))" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 required">
                    <label>{{ $t('Gender') }}</label>
                    <div class="app-radio-group">
                        <!-- Male Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Male'"
                                :checked="formData.gender === 'male'" @change="onGenderChange('male')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('male') }}
                        </label>

                        <!-- Female Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Female'"
                                :checked="formData.gender === 'female'" @change="onGenderChange('female')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('female') }}
                        </label>

                        <!-- Other Option -->
                        <label class="customized-radio radio-default">
                            <input type="radio" class="radio-inline" :name="'gender'" :id="'customRadioGender-Other'"
                                :checked="formData.gender === 'other'" @change="onGenderChange('other')" />
                            <span class="outside">
                                <span class="inside"></span>
                            </span>
                            {{ $t('other') }}
                        </label>
                    </div>
                    <div v-if="!formData.gender">
                        <small class="text-danger validation-error">
                            <!-- The gender field is required. -->
                            {{ $errorMessage(errors, 'gender') }}
                        </small>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3 required">
                    <label>{{ $t('Weight') }} ({{ weightUnit || 'kgs' }})</label>
                    <app-input type="number" v-model="formData.weight" :error-message="$errorMessage(errors, 'weight')"
                        :placeholder="$placeholder($t('Weight'))" onkeydown="if(event.key==='.'){event.preventDefault();}"
                        oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :list="ActivityLevel" list-value-field="name" :page="'modal'"
                        v-model="formData.activity_level" :error-message="$errorMessage(errors, 'activity_level')"
                        :label="$t('Activity Level')" :placeholder="$placeholder($t('Activity Level'))" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 required">
                    <!-- <app-form-group type="select" :class="'required'" :list="PrimaryComplaint" list-value-field="name"
                        :page="'modal'" v-model="formData.primary_complaint"
                        :error-message="$errorMessage(errors, 'primary_complaint')" :label="$t('Primary complaint')"
                        :placeholder="$placeholder($t('Primary complaint'))" /> -->

                    <label>{{ $t('Primary complaint') }}</label>
                    <app-input type="search-and-select" :inputclearable="false"
                        :placeholder="$t('Select Primary complaint')" :options="primaryComplaintOptions"
                        v-model="formData.primary_complaint" :error-message="$errorMessage(errors, 'primary_complaint')" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group v-model="formData.occupation" :error-message="$errorMessage(errors, 'occupation')"
                        :label="$t('Occupation')" :placeholder="$placeholder($t('Occupation'))" />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <app-form-group type="select" :class="'required'" :list="PreferredLanguage" list-value-field="name"
                        :page="'modal'" v-model="formData.preferred_language"
                        :error-message="$errorMessage(errors, 'preferred_language')" :label="$t('Preferred language')"
                        :placeholder="$placeholder($t('Preferred language'))" />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 required">
                    <label>{{ $t('Referred by') }}</label>
                    <div class="form-group">
                        <select id="referred_by"
                            class="referredby form-control"
                            name="referred_by" v-model="formData.referred_by" style="width: 100%;">
                            <option v-for="row in referredBy" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'referred_by')">{{ $errorMessage(errors,
                            'referred_by')
                        }}</small>
                    </div>
                </div>
                <div :class="getSourceColumnClass()">
                    <app-form-group type="select" :class="' required'" input-class=" form_source" :list="Source" list-value-field="name" :page="'modal'"
                        v-model="formData.source" :error-message="$errorMessage(errors, 'source')" :label="$t('Source')"
                        :placeholder="$placeholder($t('Source'))" />
                </div>
                <div :class="getCampColumnClass()">
                    <label>{{ $t('Camp Name') }}</label>
                    <div class="form-group">
                        <select id="camp_name"
                            class="campname form-control"
                            name="camp_name" v-model="formData.camp_name" style="width: 100%;">
                            <option v-for="row in campList" :key="row.id" :value="row.id">
                                {{ row.value }}
                            </option>
                        </select>
                        <small class="text-danger" v-if="$errorMessage(errors, 'camp_name')">{{ $errorMessage(errors,
                            'camp_name')
                        }}</small>
                    </div>
                </div>
                <!-- <div v-if="formData.source === 'Camp'" :class="getCampColumnClass()">
                    <label>{{ $t('Camp Name') }}</label>
                    <app-input class="margin-right-8" type="search-and-select" :inputclearable="false"
                        :placeholder="$t('Select Camp name')" :options="campsOptions" v-model="formData.camp_name"
                        :error-message="$errorMessage(errors, 'camp_name')" />
                </div> -->
            </div>

            <div class="form-group mb-4">
                <label>{{ $t('email_s') }}</label>

                <div v-for="(rowData, index) in inputEmailRow" :key="index" class="row mb-2">
                    <div class="col-md-11">
                        <app-input v-model="rowData.email" :id="'email' + index"
                            :error-message="$errorMessage(errors, 'email_details.' + index + '.email')"
                            :placeholder="$placeholder($t('email'))" />
                    </div>
                    <!-- <div class="col-md-3">
                        <app-input  v-model="rowData.email_type" :id="'email_type' + index"
                            :error-message="$errorMessage(errors, 'email_type')" :list="typeList" list-value-field="name"
                            type="select" />
                    </div> -->
                    <div class="col-md-1">
                        <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color btn-block"
                            type="button" @click="appendEmailRow">
                            <app-icon class="size-19 primary-text-color" name="plus" />
                        </button>
                        <button v-else :key="'trash' + index" class="btn default-base-color btn-block" type="button"
                            @click="removeEmailRow(index)">
                            <app-icon class="size-19 primary-text-color" name="trash" />
                        </button>
                    </div>
                </div>
            </div>

            <div class="form-group required mb-4">
                <label>{{ $t('phone_number_s') }}</label>
                <div v-for="(rowData, index) in inputPhoneNumberRow" :key="index" class="row mb-2">
                    <div class="col-md-11">
                        <app-input v-model="rowData.phone_number" type="number" :id="'phone_number' + index"
                            :error-message="$errorMessage(errors, 'phone_number_details.' + index + '.phone_number')"
                            :placeholder="$placeholder($t('phone_number'))" />
                    </div>
                    <!-- <div class="col-md-3">
                        <app-input  v-model="rowData.phone_number_type" :id="'phone_number_type' + index"
                            :error-message="$errorMessage(errors, 'phone_number_type')" :list="typeList"
                            list-value-field="name" type="select" />
                    </div> -->
                    <div class="col-md-1">
                        <button v-if="index === 0" :key="'plus' + index" class="btn default-base-color btn-block"
                            type="button" @click="appendPhoneNumberRow">
                            <app-icon class="size-19 primary-text-color" name="plus" />
                        </button>
                        <button v-else :key="'trash' + index" class="btn default-base-color btn-block" type="button"
                            @click="removePhoneNumberRow(index)">
                            <app-icon class="size-19 primary-text-color" name="trash" />
                        </button>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-md-6">
                    <label>{{ $t('tin') }}</label>
                    <app-input  v-model="formData.tin" :error-message="$errorMessage(errors, 'tin')" type="number"
                        :placeholder="$placeholder($t('tin'))" />
                </div>

            </div> -->

            <h5 class="border-bottom mb-4 pb-2">{{ $t('address') }}</h5>
            <div v-for="(rowData, index) in inputAddressRow" class="mb-2">
                <div class="row mb-3">
                    <div class="col-md-12">
                        <!-- <app-input  v-model="inputAddressRow[index].name" :id="'title' + index"
                            :error-message="$errorMessage(errors, 'address_information_details.' + index + '.name')"
                            :placeholder="$placeholder('title')" /> -->
                        <div class="col-12 col-sm-6 col-md-4">
                            <app-form-group :label="$t('Address Title')" type="radio" :list="[
                                { id: 'home', name: 'address', value: $t('home') },
                                { id: 'work', name: 'address', value: $t('work') },
                                { id: 'other', name: 'address', value: $t('other') }
                            ]" v-model="inputAddressRow[index].name" :id="'title' + index" id="'title'"
                                :error-message="$errorMessage(errors, 'address_information_details.' + index + '.name')" />
                        </div>

                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <app-input v-model="inputAddressRow[index].details" :id="'details' + index"
                            :error-message="$errorMessage(errors, 'address_information_details.' + index + '.details')"
                            :placeholder="$placeholder($t('address_details'))" type="textarea" />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <label>{{ $t('country') }}</label>
                        <app-input class="margin-right-8" type="select" :list="countryList" :key="countryList.length"
                            list-value-field="name" v-model="inputAddressRow[index].country_id"
                            :error-message="$errorMessage(errors, 'country')" />
                    </div>
                    <div class="col-md-6">
                        <label>{{ $t('City') }}</label>
                        <app-input v-model="inputAddressRow[index].city" type="text" :id="'city' + index"
                            :error-message="$errorMessage(errors, 'city')" :placeholder="$placeholder($t('city'))" />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <label>{{ $t('area') }}</label>
                        <app-input v-model="inputAddressRow[index].area" type="text" :id="'area' + index"
                            :error-message="$errorMessage(errors, 'area')" :placeholder="$placeholder($t('area'))" />
                    </div>

                    <div class="col-md-6">
                        <label>{{ $t('zip_code') }}</label>
                        <app-input v-model="inputAddressRow[index].zip_code" type="number" :id="'zip_code' + index"
                            :error-message="$errorMessage(errors, 'zip_code')"
                            :placeholder="$placeholder($t('zip_code'))" />
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                        <app-form-group  v-model="formData.payment" type="radio" :list="[
                            { id: 'N/A', value: $t('N/A') },
                            { id: 'Clear', value: $t('Clear') },
                            { id: 'Pending', value: $t('Pending') }
                        ]" :error-message="$errorMessage(errors, 'payment')" :label="$t('Payment')" />
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-form-group v-model="formData.discount" :error-message="$errorMessage(errors, 'discount')"
                            :label="$t('Discount %')" :placeholder="$placeholder($t('Discount'))" />
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-form-group :label="$t('Satisfaction status')" type="radio" :list="[
                            { id: 'N/A', value: $t('N/A') },
                            { id: 'Yes', value: $t('Yes') },
                            { id: 'No', value: $t('No') }
                        ]" v-model="formData.satisfaction_status"
                            :error-message="$errorMessage(errors, 'satisfaction_status')" />
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-check" style="top: .8rem; scale: 1.2; padding-top: 13px;">
                            <input type="checkbox" class="form-check-input" id="DND" v-model="formData.dnd">
                            <label class="form-check-label" for="DND">{{ $t('DND') }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-12">
                        <app-form-group type="textarea" :text-area-rows="3" v-model="formData.important_notes"
                            :error-message="$errorMessage(errors, 'important_notes')" :label="$t('Important Notes')"
                            :placeholder="$placeholder($t('Important Notes'))" />
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import { LINKED_CUSTOMERS, WEIGHT_UNIT, GET_CUSTOMERS_NAME } from "../../../Config/ApiUrl-CP";
import { CUSTOMERS, SELECTABLE_CUSTOMER_GROUP, ATTACHMENTS } from "../../../Config/ApiUrl-CPB";
import { COUNTRIES } from "../../../Config/ApiUrl-CP";
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import { numberToString } from "../../../../common/Helper/Support/TextHelper";
import { axiosGet, axiosPost, urlGenerator } from "../../../../common/Helper/AxiosHelper";
import { mapGetters } from 'vuex';

export default {
    name: 'CustomerCreateEditModal',
    extends: CoreLibrary,
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            selectedLinkedPatientsNames: [],
            weightUnit: '',
            attachments: [],
            userList: [],
            branchList: [],
            referredBy: [],
            campList: [],
            attachmentDescriptions: {},
            numberToString,
            customerGroupInputKey: 0,
            customerGroupOptions: {
                url: urlGenerator("app/selectable-customer-groups"),
                query_name: "search_by_name",
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: ({ id, name: value }) => ({ id, value }),
                prefetch: false,
            },
            formData: {
                customer_group_id: 1,
                addresses: {},
                contacts: {},
                tin: '',
                tenant_id: '',
                gender: '',
                satisfaction_status: 'N/A',
                title: '',
                original_branch: '',
                referred_by: '',
                camp_name: '',
                record_created_by: window.user.full_name || '',
                registration_date: '', // Added registration_date property
                addToReport: false,
                first_assigned_to: window.user.id || '',
                currently_assigned_to: window.user.id || '',
            },
            branchOrWareHoueeOptions: {
                url: urlGenerator("app/selectable/branches"),
                queryName: 'search_by_name',
                per_page: 10,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.name, type: value.type }),
            },
            Prefix: [
                { id: '', name: this.$t('Select Prefix') },
                { id: 'Mr.', name: this.$t('Mr.') },
                { id: 'Ms.', name: this.$t('Ms.') },
                { id: 'Mrs.', name: this.$t('Mrs.') },
                { id: 'Dr.', name: this.$t('Dr.') },
            ],
            CurrentUser: [
                { id: '', name: this.$t('Select User') },
                { id: window.user.full_name || '', name: this.$t(window.user.full_name || '') },
            ],
            GSTTypeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'Unregistered', name: this.$t('Unregistered') },
                { id: 'Registered', name: this.$t('Registered') },
                { id: 'Composite', name: this.$t('Composite') },
            ],
            ExaminationVenue: [
                { id: '', name: this.$t('Select Examination Venue') },
                { id: 'Branch', name: this.$t('Branch') },
                { id: 'Home Visit', name: this.$t('Home Visit') },
                { id: 'Doctors Clinic', name: this.$t('Doctors Clinic') },
                { id: 'Online', name: this.$t('Online') },
                { id: 'Camp', name: this.$t('Camp') },
            ],
            ActivityLevel: [
                { id: '', name: this.$t('Select Activity Level') },
                { id: 'Home Based', name: this.$t('Home Based') },
                { id: 'Sedentary', name: this.$t('Sedentary') },
                { id: 'Morning Walks', name: this.$t('Morning Walks') },
                { id: 'On foot Occupation', name: this.$t('On foot Occupation') },
                { id: 'Recreational sport', name: this.$t('Recreational sport') },
                { id: 'Professional sport', name: this.$t('Professional sport') },
            ],
            LegacyPatient: [
                { id: '', name: this.$t('Select Legacy Patient') },
                { id: 'Yes', name: this.$t('Yes') },
                { id: 'No', name: this.$t('No') },
            ],
            PreferredLanguage: [
                { id: '', name: this.$t('Select Preferred Language') },
                { id: 'English', name: this.$t('English') },
                { id: 'Hindi', name: this.$t('Hindi') },
                { id: 'Marathi', name: this.$t('Marathi') },
            ],
            Source: [
                { id: '', name: this.$t('Select Source') },
                { id: 'Camp', name: this.$t('Camp') },
                { id: 'Google Business Page', name: this.$t('Google Business Page') },
                { id: 'Google Ad', name: this.$t('Google Ad') },
                { id: 'Website', name: this.$t('Website') },
                { id: 'Doctor', name: this.$t('Doctor') },
                { id: 'Walk-in', name: this.$t('Walk-in') },
                { id: 'YouTube', name: this.$t('YouTube') },
                { id: 'Facebook', name: this.$t('Facebook') },
                { id: 'Instagram', name: this.$t('Instagram') },
                { id: 'Referred by friend', name: this.$t('Referred by friend') },
                { id: 'Other', name: this.$t('Other') },
            ],
            PrimaryComplaint: [
                { id: '', name: this.$t('Select Primary Complaint') },
                { id: 'Pes planus', name: this.$t('Pes planus') },
                { id: 'Pes cavus', name: this.$t('Pes cavus') },
                { id: 'Over pronation', name: this.$t('Over pronation') },
                { id: 'Over supination', name: this.$t('Over supination') },
                { id: 'Diabetic Foot Syndrome', name: this.$t('Diabetic Foot Syndrome') },
                { id: 'Corn or Callus', name: this.$t('Corn or Callus') },
                { id: 'Deformed foot', name: this.$t('Deformed foot') },
                { id: 'Knee pain', name: this.$t('Knee pain') },
                { id: 'Knocked knee', name: this.$t('Knocked knee') },
                { id: 'Bowed legs', name: this.$t('Bowed legs') },
                { id: 'Hallux valugs', name: this.$t('Hallux valugs') },
                { id: 'Low back pain', name: this.$t('Low back pain') },
                { id: 'Plantar fasciitis', name: this.$t('Plantar fasciitis') },
                { id: 'Heel spur', name: this.$t('Heel spur') },
                { id: 'Achilles tendonitis', name: this.$t('Achilles tendonitis') },
                { id: 'Sesamoiditis', name: this.$t('Sesamoiditis') },
                { id: 'Toe pain', name: this.$t('Toe pain') },
                { id: 'Metatarsalgia', name: this.$t('Metatarsalgia') },
                { id: 'Mortons neuroma', name: this.$t('Mortons neuroma') },
                { id: 'Charcot foot', name: this.$t('Charcot foot') },
                { id: 'Diabetic wound', name: this.$t('Diabetic wound') },
                { id: 'Edema', name: this.$t('Edema') },
                { id: 'LLD', name: this.$t('LLD') },
                { id: 'Navicular drop or drift', name: this.$t('Navicular drop or drift') },
            ],
            linkedPatientsList: [],
            maxSelections: 3,
            userOptions: {
                url: urlGenerator("app/selectable/users"),
                queryName: 'search_by_name',
                per_page: 50,
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            },
            CUSTOMERS,
            SELECTABLE_CUSTOMER_GROUP,
            COUNTRIES,
            typeList: [
                { id: '', name: this.$t('select_type') },
                { id: 'home', name: this.$t('home') },
                { id: 'work', name: this.$t('work') },
                { id: 'office', name: this.$t('office') }
            ],
            countryList: [],
            inputAddressRow: [],
            index: 0,
            inputEmailRow: [],
            removableEmailRow: true,
            inputPhoneNumberRow: [],
            removablePhoneNumberRow: true,
            inputAddressRow: [
                {
                    name: "",
                    city: "",
                    country_id: "",
                    zip_code: "",
                    area: "",
                    details: "",
                }
            ],
            removableAddressRow: true,
        }
    },
    created() {
        this.getCountries();
        // if (!this.selectedUrl) this.setDefaultGroupId();

        this.loadWeightUnit();
        if (this.formData.original_branch) {
            this.fetchlinkedCustomers();
            this.fetchLinkedDoctors();
            this.fetchLinkedCamps();
        }
        this.userListOptions();
        this.branchListOptions();
    },
    watch: {
        inputEmailRow() {
            this.removableEmailRow = this.inputEmailRow.length <= 1;
        },
        inputPhoneNumberRow() {
            this.removablePhoneNumberRow = this.inputPhoneNumberRow.length <= 1;
        },

        'formData.date_of_birth'(newDateOfBirth) {
            if (newDateOfBirth) {
                const today = new Date();
                const birthDate = new Date(newDateOfBirth);
                const age = today.getFullYear() - birthDate.getFullYear();

                // Adjust age based on month and day of birth
                if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
                    this.formData.age = age - 1; // Update the age property directly
                } else {
                    this.formData.age = age; // Update the age property directly
                }
            }
        },

        // 'getUserId': {
        //     immediate: true,
        //     handler(newId) {
        //         if (!newId) return;
        //         this.formData.first_assigned_to = newId;
        //         this.formData.currently_assigned_to = newId;
        //         this.ssKey++;
        //         console.log(this.formData.currently_assigned_to);
        //     }
        // },

        'getBranchOrWarehouseId': {
            immediate: true,
            handler(newId) {
                if (!newId) return;
                this.formData.original_branch = newId;
                this.ssKey++;
            }
        }
    },
    mounted() {
        this.appendEmailRow();
        this.appendPhoneNumberRow();
        this.formData.tenant_id = this.getTenantId;
        this.setInitialRegistrationDate(); // Set initial value of registration_date

        // Initialize Select2
        let self = this;
        $('.first_assigned_to').select2({ placeholder: 'Select a user',dropdownParent: $("#customer-modal") }).on("change", function () {
            self.onSelectChangeFirstAssignedTo(this.value);
        });

        $('.original_branch').select2({ placeholder: 'Select a user',dropdownParent: $("#customer-modal") }).on("change", function () {
            self.onSelectChangeOriginalBranch(this.value);
        }); 
        $('.referredby').select2({ placeholder: 'Select Referred by', dropdownParent: $("#customer-modal") }).on("change", function () {
            self.onSelectChangeReferredBy(this.value);
        }); 

        $('.currently_assigned_to').select2({ placeholder: 'Select a user', }).on("change", function () {
            self.onSelectChange(this.value);
        });
        $('.campname').select2({ placeholder: 'Select Camp name', dropdownParent: $("#customer-modal") }).on("change", function () {
            self.onSelectChangeCampName(this.value);
        });
    },
    computed: {
        ...mapGetters(["getTenantId"]),
        ...mapGetters(['getBranchOrWarehouseName']),
        ...mapGetters(['getBranchOrWarehouseId']),
        ...mapGetters(['getUserId']),

        primaryComplaintOptions() {
            return {
                url: urlGenerator("app/selectable-pathologies"),
                // queryName: 'search_by_name',
                query_name: "search_by_name",
                per_page: 50,
                // params: { 'branch_id': this.getBranchOrWarehouseId, },
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.chief_complaint_name }),
            }
        },

        doctorsOptions() {
            if (this.formData.original_branch == '') {
                // Return an empty object or null if original_branch is null
                return {};
            }

            // If original_branch is available, construct and return the API call object
            return {
                url: urlGenerator("app/selectable/doctors"),
                queryName: 'search_by_name',
                per_page: 50,
                params: { 'branch_id': this.formData.original_branch },
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.full_name }),
            }
        },

        campsOptions() {
            if (this.formData.original_branch === null) {
                // Return an empty object or null if original_branch is null
                return null;
            }

            // If original_branch is available, construct and return the API call object
            return {
                url: urlGenerator("app/selectable/camps"),
                queryName: 'search_by_name',
                per_page: 50,
                params: { 'branch_id': this.formData.original_branch },
                loader: "app-pre-loader", // by default 'app-overlay-loader'
                modifire: (value) => ({ id: value.id, value: value.camp_name }),
            }
        },
    },
    methods: {
        onSelectChangeFirstAssignedTo(val) {
            this.formData.first_assigned_to = val;
        },
        onSelectChangeOriginalBranch(val) {
            this.formData.original_branch = val;
            // this.formData.source = null;
            this.fetchlinkedCustomers();
            this.fetchLinkedDoctors();
            this.fetchLinkedCamps();
        },
        onSelectChange(val) {
            this.formData.currently_assigned_to = val;
        },
        onSelectChangeCampName(val) {
            this.formData.camp_name = val;
        },
        onSelectChangeReferredBy(val) {
            this.formData.referred_by = val;
        },
        userListOptions() {
            axiosGet(`app/selectable/users`).then(response => {
                for (const item of response.data.data) {
                    this.userList.push({
                        id: item.id,
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchLinkedDoctors() {
            this.referredBy = [];
            axiosGet(`app/selectable/doctors?branch_id=${this.formData.original_branch}`).then(response => {
                for (const item of response.data.data) {
                    this.referredBy.push({
                        id: item.id,
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        fetchLinkedCamps() {
            this.campList = [];
            axiosGet(`app/selectable/camps?branch_id=${this.formData.original_branch}`).then(response => {
                for (const item of response.data.data) {
                    this.campList.push({
                        id: item.id,
                        value: item.camp_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        branchListOptions() {
            axiosGet(`app/selectable/branches`).then(response => {
                for (const item of response.data.data) {
                    this.branchList.push({
                        id: item.id,
                        value: item.name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        onGenderChange(selectedGender) {
            // Access the selected gender
            this.formData.gender = selectedGender;

            // Perform any action based on the selected gender
            console.log(`Selected gender: ${selectedGender}`);
        },
        handleLinkedPatientsChange() {
            console.log(this.formData.linked_patients.length);
            if (this.formData.linked_patients.length > this.maxSelections) {
                // If the user exceeds the maximum allowed selections, remove the latest added patients
                const removedPatients = this.formData.linked_patients.splice(this.maxSelections);
                // Show an error message to inform the user about the limit
                alert(`You can select up to ${this.maxSelections} linked patients.`);
            }
        },
        getSourceColumnClass() {
            return this.formData.source === 'Camp' ? 'col-12 col-sm-6 col-md-4' : 'col-12 col-sm-6 col-md-4';
        },
        getCampColumnClass() {
            return this.formData.source === 'Camp' ? 'col-12 col-sm-6 col-md-4' : 'd-none'; // Hide if source is not 'Camp'
        },
        loadWeightUnit() {
            const branchId = this.getBranchOrWarehouseId;
            // console.log(branchId);
            axiosGet(`${WEIGHT_UNIT}/${branchId}`).then(response => {
                // console.log('response:', response.data.weight_unit);
                const weightUnit = response.data.weight_unit;

                // Set the weightUnit value in the data
                this.weightUnit = weightUnit;
            }).catch(error => {
                console.error(error);
            });
        },
        fetchlinkedCustomers() {
            const branchId = this.formData.original_branch;
            this.linkedPatientsList = [];
            this.formData.linked_patients = [];
            axiosGet(`${LINKED_CUSTOMERS}/${branchId}`).then(response => {
                console.log('response:', response.data.data);

                for (const item of response.data.data) {
                    this.linkedPatientsList.push({
                        id: item.id.toString(),
                        value: item.full_name,
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1; // Note: month is zero-based
            let day = today.getDate();

            // Pad month and day with leading zeroes if necessary
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${year}-${month}-${day}`;
        },

        setInitialRegistrationDate() {
            this.formData.registration_date = this.getCurrentDate(); // Set initial value of registration_date
        },
        getCountries() {
            axiosGet(COUNTRIES)
                .then(data => {
                    this.countryList = [{ name: this.$t('select_country') }, ...data.data];

                    // Find the ID of the country "India" in the countryList
                    const indiaCountry = this.countryList.find(country => country.name === 'India');
                    if (indiaCountry) {
                        // Set the country_id for India
                        this.inputAddressRow[this.index].country_id = indiaCountry.id;
                    } else {
                        console.log('India not found in country list.');
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitData() {
            this.fieldStatus.isSubmit = true;
            this.loading = true;
            this.message = '';
            this.errors = {};

            this.formData.first_assigned_to = $($(".first_assigned_to")[0]).val();
            this.formData.currently_assigned_to = $($(".currently_assigned_to")[0]).val();
            this.formData.original_branch = $($(".original_branch")[0]).val();
            this.formData.referred_by = $($(".referredby")[0]).val();
            this.formData.camp_name = $($(".campname")[0]).val();



            this.formData.email_details = this.inputEmailRow.map((item) => ({
                'email': item.email,
                'email_type': item.email_type,
            })
            );
            this.formData.email = this.formData.email_details[0].email;

            this.formData.phone_number_details = this.inputPhoneNumberRow.map((item) => ({
                'phone_number': item.phone_number,
                'phone_number_type': item.phone_number_type,
            })
            );

            const existingAddresses = this.formData.addresses.length ? this.formData.addresses.filter(address => address.id !== this.inputAddressRow[0].id) : [];

            this.formData.address_information_details =
                [...existingAddresses, ...this.inputAddressRow].map((item) => ({
                    'name': item.name,
                    'country_id': item.country_id,
                    'city': item.city,
                    'zip_code': item.zip_code,
                    'area': item.area,
                    'details': item.details,
                })
                );

            this.formData.branch_id = this.getBranchOrWarehouseId;
            // console.log(this.formData.branch_id);

            this.save(this.formData);
        },
        afterSuccess({ data }) {
            this.formData = {};
            this.$emit('afterSubmitSuccess');
            $('#customer-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'customer-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
            if (this.formData.addresses.length) this.inputAddressRow = [this.formData.addresses[0]];
            if (this.formData.contacts.email.length) this.inputEmailRow = this.formData.contacts.email.map((item) => ({
                email: item.value,
                email_type: item.type
            })
            );
            if (this.formData.contacts.phone_number.length) this.inputPhoneNumberRow = this.formData.contacts.phone_number.map((item) => ({
                phone_number: item.value,
                phone_number_type: item.type
            })
            );
        },
        setDefaultGroupId() {
            this.axiosGet(SELECTABLE_CUSTOMER_GROUP).then((response) => {
                let id;
                response.data.filter((item) => {
                    if (+item.is_default === 1) id = item.id;
                })
                this.formData.customer_group_id = id;
                this.customerGroupInputKey++;
            })
        },
        appendEmailRow() {
            let checkEmptyRows = this.inputEmailRow.filter(line => line.number === null);
            if (checkEmptyRows.length >= 1 && this.inputEmailRow.length > 0) return;
            this.inputEmailRow.push({});
        },
        removeEmailRow(rowId) {
            if (!this.removableEmailRow) this.inputEmailRow.splice(rowId, 1);
        },
        appendPhoneNumberRow() {
            let checkEmptyRows = this.inputPhoneNumberRow.filter(line => line.number === null);
            if (checkEmptyRows.length >= 1 && this.inputPhoneNumberRow.length > 0) return;
            this.inputPhoneNumberRow.push({});
        },
        removePhoneNumberRow(rowId) {
            if (!this.removablePhoneNumberRow) this.inputPhoneNumberRow.splice(rowId, 1);
        },
        removeAddressRow(rowId) {
            if (!this.removableAddressRow) this.inputAddressRow.splice(rowId, 1);
        },
    },
}
</script>

<style>
.required>label:first-child:after {
    color: #e32;
    content: ' *';
    display: inline;
}


/* Increase the width of the Select2 input */
.select2-container--default .select2-selection--single {
    width: 100%;
    border: 1px solid #ced4da;
}

/* Increase the height of the Select2 input */
.select2-container--default .select2-selection--single {
    height: 40px;
}

/* Increase padding and font size */
.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 5px 10px;
    font-size: 16px;
}
</style>