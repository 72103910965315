import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import { PAYMENTS } from "../../../../Config/ApiUrl-CPB";
import { SELECTABLE_USERS,SELECTABLE_DROPDOWN } from "../../../../Config/ApiUrl-CP";
import {formatDateToLocal} from "../../../../Helper/Helper";
import { axiosGet, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import {mapActions, mapGetters} from "vuex";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: null,
                showCount: true,
                showClearFilter: true,
                showHeader: true,
                columns: [
                    {
                        title: this.$t('Payment date'),
                        type: 'custom-html',
                        key: 'created_at',
                        modifier: (date) => `<p class="">${formatDateToLocal(date)}</p>`,
                    },
                    {
                        title: this.$t('Order No.'),
                        type: 'text',
                        key: 'order_id',
                    },
                    {
                        title: this.$t('Branch'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (row) => row.branch_or_warehouse ? `<p class="">${row.branch_or_warehouse?.name}</p>` :'-',
                    },
                    {
                        title: this.$t('Patient'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (row) => row.customer ? `<p class="">${row.customer?.full_name}</p>` :'-',
                    },
                    {
                        title: this.$t('Amount'),
                        type: 'text',
                        key: 'paid_amount',
                    },
                    {
                        title: this.$t('Type'),
                        type: 'custom-html',
                        key: 'payment_type',
                        modifier: (payment_type) => `<p class="">${payment_type == 1?'Payment':'Receipt'}</p>`,
                    },
                    {
                        title: this.$t('Mode'),
                        type: 'custom-html',
                        key: 'payment_mode',
                        modifier: (row) => row?.item_label ? `<p class="">${row?.item_label}</p>` :'-',
                    },
                    {
                        title: this.$t('Note'),
                        type: 'text',
                        key: 'payment_note',
                    },
                    {
                        title: this.$t('Practitioner'),
                        type: 'custom-html',
                        key: 'order',
                        modifier: (row) => row.customer.users ? `<p class="">${row.customer?.users?.full_name}</p>` :'-',
                    },
                    {
                        title: this.$t('Verified (yes/no)'),
                        type: 'custom-html',
                        key: 'verified',
                        modifier: (verified) => verified == 1 ? `<p class="text-success">Yes</p>` :`<p class="text-danger">No</p>`,
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'order',
                        isVisible: this.$can('isadmin')??false,
                    },
                ],
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        component: 'app-tenant-group-modal',
                        modalId: 'app-modal',
                        modifier: () => this.$can('isadmin'),
                    }
                ],
                filters: [
                    {
                        title: "Practitioner",
                        type: "drop-down-filter",
                        key: "practitioner",
                        option: [],
                    },
                    {
                        title: "Payment Mode",
                        type: "drop-down-filter",
                        key: "payment_mode",
                        option: [],
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                search: true,
            },
        }
    },
    methods: {
        ...mapActions(['setCampsByBranchOrWarehouseId']),
        filterInitiate() {
            this.practitionerList();
            this.payemntModeList();
        },
        practitionerList() {
            axiosGet(SELECTABLE_USERS).then((response) => {
                let name = this.options.filters.find(element => element.key === 'practitioner');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.full_name
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
        payemntModeList() {
            axiosGet(SELECTABLE_DROPDOWN+`?list_id=14`).then((response) => {
                let name = this.options.filters.find(element => element.key === 'payment_mode');
                if (name) name.option = response.data.data.map(name => {
                    return {
                        id: name.id,
                        value: name.item_label,
                    }
                });
            }).catch((error) => {
                this.$toastr.e(error);
            });
        },
    },
    computed:{
        ...mapGetters(['getBranchOrWarehouseId']),
    },
    mounted(){
        this.updateUrl(PAYMENTS);
    },
    watch: {
        getBranchOrWarehouseId: {
            immediate: false,
            handler() {
                this.updateUrl(PAYMENTS);
            }
        }
    },
    created() {
        this.filterInitiate();
    },
}
