<template>
    <section id="app-inventory-stock" class="content-wrapper">
        <!-- top page section -->
        <app-page-top-section :title="$t('orders')" />

        <app-table :id="table_id" class="tblProductFixHead table-striped" v-if="options.url" :options="options" @action="triggerAction" />

        <app-orders-modal v-if="isOrdersModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />

        <app-due-payment-modal v-if="isModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />
        <app-reverse-payment-modal v-if="isReverseModalActive" :order-id="orderId" :value="true" @modal-close="closeModal" />

    </section>
</template>

<script>

import DatatableHelperMixin from "../../../../../common/Mixin/Global/DatatableHelperMixin";
import SelectableStatusMixin from "../../../../Helper/SelectableOptions/SelectableStatusMixin";
import invoiceList from "./ordersList";
import { GENERATE_INVOICE, GENERATE_PERFOMA_INVOICE, GENERATE_ORDER_CONFIRMATION, GENERATE_ORDER_FORM } from "../../../../Config/ApiUrl-CP";
import { urlGenerator } from "../../../../../common/Helper/AxiosHelper";

export default {
    name: 'OrdersView',
    mixins: [DatatableHelperMixin, SelectableStatusMixin, invoiceList],
    data() {
        return {
            table_id: 'invoice-list-table',
            orderId: '',
            isModalActive: false,
            isReverseModalActive: false,
            isOrdersModalActive: false,
        };
    },
    methods: {
        triggerAction(row, action, active) {
            this.orderId = row.id;
            if (action.type === 'view') {
                this.isOrdersModalActive = true;
            } else if (action.type === 'due_receive') {
                this.isModalActive = true;
            } else if (action.type === 'reverse_payment') {
                this.isReverseModalActive = true;
            } else if (action.type === 'edit') {
                window.location.replace(urlGenerator(`order/details/${row.id}`));
            } else if (action.type === 'download_invoice') {
                window.open(GENERATE_INVOICE + this.orderId,'_blank');
            } else if (action.type === 'download_perfoma_invoice') {
                window.open(GENERATE_PERFOMA_INVOICE + this.orderId,'_blank');
            } else if (action.type === 'print_order_confirmation') {
                window.open(GENERATE_ORDER_CONFIRMATION + this.orderId,'_blank');
            } else if (action.type === 'print_order_form') {
                window.open(GENERATE_ORDER_FORM + this.orderId,'_blank');
            }
        },
        closeModal() {
            $('#orders-view-modal').modal('hide')
            this.isOrdersModalActive = false;
            this.isModalActive = false
            this.isReverseModalActive = false
            this.$emit('modal-close');
        },
        openModal() {
            this.isModalActive = true;
            this.isReverseModalActive = true;
            this.selectedUrl = ''
        }
    }
};
</script>
<style>
.tblProductFixHead .table-responsive.custom-scrollbar { overflow: auto; height: 600px; padding-top: 0 !important;}
.tblProductFixHead .table-responsive.custom-scrollbar table { width: 2000px; }
.tblProductFixHead .table-responsive.custom-scrollbar table thead { position: sticky; top: 0; z-index: 2; background-color: #fff !important; }
.tblProductFixHead .table-responsive.custom-scrollbar table thead tr th:first-child { position: sticky; left: 0; z-index: 2; background-color: #fff !important;}
.tblProductFixHead .table-responsive.custom-scrollbar table tbody tr td:first-child { position: sticky; left: 0; z-index: 1; }
.tblProductFixHead .table-responsive.custom-scrollbar table thead th { padding-top: 2rem !important; }
.tblProductFixHead .table-responsive.custom-scrollbar table tbody tr:nth-child(even) td:first-child { background-color: #fff }
.tblProductFixHead .table-responsive.custom-scrollbar table tbody tr:nth-child(odd) td:first-child { background-color: #f2f2f2 }
</style>
