import { TENANT_BASE_URL } from '../../common/Config/UrlHelper';


export const IMPORT_PRODUCT = `${TENANT_BASE_URL}app/import/products`;
export const TENANT_GENERAL_SETTING_URL = `${TENANT_BASE_URL}general/settings`;
export const TENANTS = `app/user/tenants`;
export const SELECTABLE_ROLE = `${TENANT_BASE_URL}selectable/roles`;
export { TENANT_SELECTABLE_USER as TENANT_SELECTABLE_USER } from "../../common/Config/apiUrl";
export const TEST_MAIL = `${TENANT_BASE_URL}app/test-mail/send`;

// Lists
export const MODULE = `${TENANT_BASE_URL}app/module-list`;
export const LISTS = `${TENANT_BASE_URL}app/dropdown-list`;
export const LISTS_ITEMS = `${TENANT_BASE_URL}app/list-items`;

//Customer & Customer Group
export const DOCTORS = `${TENANT_BASE_URL}app/doctors`;
export const CAMPS = `${TENANT_BASE_URL}app/camps`;
export const TECHNICIANS = `${TENANT_BASE_URL}app/technicians`;
export const CHIEF_COMPLAINT = `${TENANT_BASE_URL}app/chief-complaint`;
export const CUSTOMERS = `${TENANT_BASE_URL}app/customers`;
export const SALES_CUSTOMERS = `${TENANT_BASE_URL}app/customers/sales_store`;
export const ATTACHMENTS = `${TENANT_BASE_URL}app/attachments`;
export const FOOTPRESSURE = `${TENANT_BASE_URL}app/foot-pressure-analysis`;
export const FOOTPRESSURELATEST = `${TENANT_BASE_URL}app/foot-pressure-analysis-latest`;
export const FOOTPRESSUREORTHOTICS = `${TENANT_BASE_URL}app/foot-pressure-analysis-orthotics`;
export const DELETE_IMAGE = `${TENANT_BASE_URL}app/delete-image/`;
export const DYNAMIC_ANALYSIS = `${TENANT_BASE_URL}app/dynamic-analysis`;
export const DYNAMIC_ANALYSIS_LATEST = `${TENANT_BASE_URL}app/dynamic-analysis-latest`;
export const DYNAMIC_ANALYSIS_ORTHOTICS = `${TENANT_BASE_URL}app/dynamic-analysis-orthotics`;
export const DIABETIC_FOOT = `${TENANT_BASE_URL}app/diabetic-foot-risk`;
export const POSTERIOR_VIEW = `${TENANT_BASE_URL}app/posterior-view-static`;
export const TOP_VIEW_WEIGHT_BEARING = `${TENANT_BASE_URL}app/top-view-weight-bearing`;
export const MEDIAL_VIEW_WEIGHT_BEARING = `${TENANT_BASE_URL}app/medial-view-weight-bearing`;
export const PLANTAR_VIEW_WEIGHT_BEARING = `${TENANT_BASE_URL}app/plantar-view-weight-bearing`;
export const PLANTAR_VIEW_NON_WEIGHT_BEARING = `${TENANT_BASE_URL}app/plantar-view-non-weight-bearing`;
export const POSTERIOR_VIEW_IN_GAIT = `${TENANT_BASE_URL}app/posterior-view-in-gait`;
export const ANTERIOR_VIEW_IN_GAIT = `${TENANT_BASE_URL}app/anterior-view-in-gait`;
export const POSTURE = `${TENANT_BASE_URL}app/posture`;
export const IMAGE_WITH_CORRECTION = `${TENANT_BASE_URL}app/image-with-correction`;
export const GET_ALL = `${TENANT_BASE_URL}app/fetch-all-examination-data`;
export const NOTES = `${TENANT_BASE_URL}app/notes`;
export const DELETE_NOTES = `${TENANT_BASE_URL}app/delete-note`;
export const FIELDS = `${TENANT_BASE_URL}app/fields`;
export const DROPDOWN_OPTIONS = `${TENANT_BASE_URL}app/dropdown_options`;
export const EXAMINATIONS = `${TENANT_BASE_URL}app/examinations`;
export const MEASUREMENTFIELDS = `${TENANT_BASE_URL}app/measurement-fields`;
export const GET_MEASUREMENT = `${TENANT_BASE_URL}app/measurements`;
export const MEASUREMENT = `${TENANT_BASE_URL}app/measurements`;
export const MEASUREMENT_FILES = `${TENANT_BASE_URL}app/measurement-files`;
export const DELETE_MEASUREMENT_FILES = `${TENANT_BASE_URL}app/delete-measurement-files/`;
export const MEASUREMENT_UNIT = `${TENANT_BASE_URL}app/branch-measurements-unit`;
export const CUSTOMER_ORDERS = `${TENANT_BASE_URL}app/customer/orders/`;
export const CUSTOMER_LIST = `${TENANT_BASE_URL}customer/lists`;
export const CUSTOMER_PROFILE_PICTURE_UPLOAD = `${TENANT_BASE_URL}app/customer/profile/picture/`;
export const CUSTOMER_DETAILS = `${TENANT_BASE_URL}/customer/details/`;
export const CUSTOMER_DETAILS_VIEW = `${TENANT_BASE_URL}/customer/view/`;
export const ORDER_DETAILS = `${TENANT_BASE_URL}/order/details/`;
export const PAYMENTS = `${TENANT_BASE_URL}/app/payments/`;
export const CUSTOMER_DATA = `${TENANT_BASE_URL}app/customer/details`;
export const CASH_REGISTER = `${TENANT_BASE_URL}app/selectable-cash-register`;
export const PERSONAL_INFO_UPDATE = `${TENANT_BASE_URL}/customer/details/`;
export const ADDRESS = `${TENANT_BASE_URL}app/customer/address`;
export const CUSTOMER_ADDRESS = `${TENANT_BASE_URL}app/address`;
export const CUSTOMER_INFO = `${TENANT_BASE_URL}app/customer/info`;
export const CUSTOMERS_COUNT = `${TENANT_BASE_URL}customer/count`;

// Customer Export
export const CUSTOMERS_EXPORT = `/${TENANT_BASE_URL}export/customers`;
export const CUSTOMER_EXPORT_SHEET = `${TENANT_BASE_URL}/export/sheet/`;

//Import Customer
export const CUSTOMERS_IMPORT = `${TENANT_BASE_URL}app/customers-import`;
export const CUSTOMERS_STORE = `${TENANT_BASE_URL}/app/customers/bulk-import`;

// Supplier
export const SUPPLIERS = `${TENANT_BASE_URL}app/suppliers`;
export const SUPPLIER_LIST = `${TENANT_BASE_URL}supplier/lists`;
export const SUPPLIER_DETAILS = `${TENANT_BASE_URL}/supplier/details/`;
export const SUPPLIER_PROFILE_PICTURE_UPLOAD = `${TENANT_BASE_URL}app/supplier/profile/picture/`;
export const SUPPLIER_ADDRESS = `${TENANT_BASE_URL}/app/supplier/address`;
export const SUPPLIER_ADDRESSES = `${TENANT_BASE_URL}app/supplier-all-address/`;



// Customer Group
export const CUSTOMER_GROUP = `${TENANT_BASE_URL}app/customer-groups`;
export const SELECTABLE_CUSTOMER_GROUP = `${TENANT_BASE_URL}selectable/customer/group`;

//Branch
export const SELECTABLE_BRANCH = `${TENANT_BASE_URL}selectable/branch`;
export const SELECTABLE_BRANCHES = `${TENANT_BASE_URL}app/selectable/branches`;
export const SELECTABLE_TECHNICIAN = `${TENANT_BASE_URL}app/selectable/technician`;
export const GET_BRANCH_STATUS = `${TENANT_BASE_URL}app/branch-status`;
export const BRANCH_OR_WAREHOUSE = `${TENANT_BASE_URL}app/branch_or_warehouses`;
export const BRANCHES_OR_WAREHOUSES = `${TENANT_BASE_URL}app/branch_or_warehouses`;

// Specialisation
export const SELECTABLE_SPECIALISATION = `${TENANT_BASE_URL}app/selectable/specialisation`;
export const SELECTABLE_SHOE_TYPE = `${TENANT_BASE_URL}app/selectable/shoe-type`;
export const SELECTABLE_TEMPLATE_LENGTH_OR_WIDTH = `${TENANT_BASE_URL}app/selectable/template-length-or-width`;
export const SELECTABLE_SHOE_SIZE = `${TENANT_BASE_URL}app/selectable/shoe-size`;
export const SELECTABLE_MOULD = `${TENANT_BASE_URL}app/selectable/mould`;

// Counter
export const COUNTERS = `${TENANT_BASE_URL}app/counters`;
export const SALES_PEOPLE = `${TENANT_BASE_URL}selectable-sales-person`;

//Invoice Template
export const INVOICE_TEMPLATE = `${TENANT_BASE_URL}app/invoice-templates`;
export const SELECTABLE_INVOICE_TEMPLATE = `${TENANT_BASE_URL}selectable/invoice-templates`;

//Tax
export const TAX = `${TENANT_BASE_URL}app/tax`;
export const ALL_TAX = `${TENANT_BASE_URL}app/tax?all=true`;
export const GET_TAX = `${TENANT_BASE_URL}app/get-tax`;
export const SELECTABLE_TAX = `${TENANT_BASE_URL}app/selectable-tax`;

//Coupon
export const COUPONS = `${TENANT_BASE_URL}app/coupons`;
export const SELECTABLE_DISCOUNTS = `${TENANT_BASE_URL}app/selectable-discounts`;


//Payment Methods
export const PAYMENT_METHOD = `${TENANT_BASE_URL}app/payment-method`;
export const GET_PAYMENT_METHOD_STATUS = `${TENANT_BASE_URL}app/payment-method-status`;

//Sms setting
export const SMS_SETTING = `${TENANT_BASE_URL}app/sms-settings`;
export const GET_SMS_SETTING = `${TENANT_BASE_URL}app/sms-setting-info`;

//Sms Template
export const SMS_TEMPLATE = `${TENANT_BASE_URL}app/sms-templates`;

//Import Supplier
export const SUPPLIER_IMPORT = `${TENANT_BASE_URL}app/supplier-import`;
export const SUPPLIER_STORE = `${TENANT_BASE_URL}/app/supplier/balk-import`;
export const SUPPLIER_EXPORT = `/${TENANT_BASE_URL}export/supplier/`;

//Payment
export const PAYPAL_ACTION_URL = `${TENANT_BASE_URL}/payment-paypal-action`;
export const STRIPE_ACTION_URL = `${TENANT_BASE_URL}/payment-stripe-action`;

//Report
export const LOT_REPORT = `${TENANT_BASE_URL}report/purchase`;
export const PURCHASE_SUMMARY = `${TENANT_BASE_URL}/report/purchase-summary`;
export const PURCHASE_REPORT_EXPORT = `${TENANT_BASE_URL}/report/purchase-export`;

//Supplier report
export const SUPPLIER_REPORT = `${TENANT_BASE_URL}report/supplier`;
export const SUPPLIER_SUMMARY = `${TENANT_BASE_URL}/report/supplier-summary`;
export const SUPPLIER_REPORT_EXPORT = `${TENANT_BASE_URL}/report/supplier-export`;

//stock / inventory report
export const PRODUCT_STOCK_REPORT_EXPORT = `${TENANT_BASE_URL}/report/product-stock-export`;
export const PRODUCT_STOCK_REPORT = `${TENANT_BASE_URL}report/product-stock`;

//Customer report
export const CUSTOMER_REPORT = `${TENANT_BASE_URL}report/customer`;
export const CUSTOMER_REPORT_EXPORT = `${TENANT_BASE_URL}/report/customer-export`;
export const LOG_REPORT = `${TENANT_BASE_URL}report/log`;
