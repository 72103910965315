<template>
  <fieldset :disabled="isDisable">
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-12 my-2 text-right" v-if="measurementsPrintUrl != '' ">
          <a :href="measurementsPrintUrl" target="_blank" class="btn btn-primary">Print</a>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <h4>Measurements</h4>
            </div>
            <div class="col-md-4">
              <h4>Left ({{ measurementsUnit || 'mm' }})</h4>
            </div>
            <div class="col-md-4">
              <h4>Right ({{ measurementsUnit || 'mm' }})</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12 mb-3">
          <div class="row" v-for="(field, index) in measurementFields" :key="field.id">
            <div class="col-md-4 d-flex align-items-center">
              <label :for="`${field.name}Left`">{{ field.name }}
                <span v-if="index < measurementFields.length - 4">({{ measurementsUnit || 'mm' }})</span>
              </label>
            </div>
            <div class="col-md-4 mb-3">
              <input v-model="formData[`left_${field.id}`]" type="number"
                :placeholder="getLeftPlaceholder(field.name, index)" class="form-control">
            </div>
            <div class="col-md-4 mb-3">
              <input v-model="formData[`right_${field.id}`]" type="number"
                :placeholder="getRightPlaceholder(field.name, index)" class="form-control">
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <div class="mt-primary">
            <button class="btn btn-primary" v-if="!isDisable" type="submit">{{ $t('Save') }}</button>
          </div>
        </div>
      </div>

      <div id="accordionExample" class="accordion mb-4">
        <!-- Upload Foot Outlines PDF -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadFootOutlinesPDF" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Foot Outlines PDF') }}
                <span v-if="!arrayNull(UploadFootOutlinesPDF)" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="UploadFootOutlinesPDF" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootOutlinesPDF-imageLeft"
                      @change="handleImagesUpload('UploadFootOutlinesPDF', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootOutlinesPDF.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootOutlinesPDF.imageLeft && UploadFootOutlinesPDF.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadFootOutlinesPDF.imageLeftUrl && !UploadFootOutlinesPDF.imageLeft">
                    {{
                      UploadFootOutlinesPDF.imageLeftUrl }}</div>
                  <div v-if="!UploadFootOutlinesPDF.imageLeftUrl && UploadFootOutlinesPDF.imageLeft">
                    <div class="row col-md-6 mb-2">
                      <img :src="urlGenerator(UploadFootOutlinesPDF.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootOutlinesPDF.imageLeft)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootOutlinesPDF.imageLeft)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootOutlinesPDF', UploadFootOutlinesPDF.measurementFiledID, 'imageLeft')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootOutlinesPDF-imageRight"
                      @change="handleImagesUpload('UploadFootOutlinesPDF', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootOutlinesPDF.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootOutlinesPDF.imageRight && UploadFootOutlinesPDF.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadFootOutlinesPDF.imageRightUrl && !UploadFootOutlinesPDF.imageRight">
                    {{
                      UploadFootOutlinesPDF.imageRightUrl }}</div>
                  <div v-if="!UploadFootOutlinesPDF.imageRightUrl && UploadFootOutlinesPDF.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadFootOutlinesPDF.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootOutlinesPDF.imageRight)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootOutlinesPDF.imageRight)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootOutlinesPDF', UploadFootOutlinesPDF.measurementFiledID, 'imageRight')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootOutlinesPDF-imageBoth"
                      @change="handleImagesUpload('UploadFootOutlinesPDF', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootOutlinesPDF.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootOutlinesPDF.imageBoth && UploadFootOutlinesPDF.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadFootOutlinesPDF.imageBothUrl && !UploadFootOutlinesPDF.imageBoth">
                    {{
                      UploadFootOutlinesPDF.imageBothUrl }}</div>
                  <div v-if="!UploadFootOutlinesPDF.imageBothUrl && UploadFootOutlinesPDF.imageBoth">
                    <div class="row-col-md-6">
                      <img :src="urlGenerator(UploadFootOutlinesPDF.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                      </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootOutlinesPDF.imageBoth)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootOutlinesPDF.imageBoth)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootOutlinesPDF', UploadFootOutlinesPDF.measurementFiledID, 'imageBoth')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadFootOutlinesPDF')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Footbed CDR -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadFootbedCDR" aria-expanded="false" aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Footbed CDR') }}
                <span v-if="!arrayNull(UploadFootbedCDR)" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="UploadFootbedCDR" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootbedCDR-imageLeft" @change="handleImagesUpload('UploadFootbedCDR', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootbedCDR.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootbedCDR.imageLeft && UploadFootbedCDR.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadFootbedCDR.imageLeftUrl && !UploadFootbedCDR.imageLeft">{{
                    UploadFootbedCDR.imageLeftUrl }}</div>
                  <div v-if="!UploadFootbedCDR.imageLeftUrl && UploadFootbedCDR.imageLeft">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadFootbedCDR.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootbedCDR.imageLeft)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootbedCDR.imageLeft)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootbedCDR', UploadFootbedCDR.measurementFiledID, 'imageLeft')"><app-icon name="trash-2"/></a>
                        
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootbedCDR-imageRight" @change="handleImagesUpload('UploadFootbedCDR', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootbedCDR.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootbedCDR.imageRight && UploadFootbedCDR.imageRightUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadFootbedCDR.imageRightUrl && !UploadFootbedCDR.imageRight">{{
                    UploadFootbedCDR.imageRightUrl }}</div>
                  <div v-if="!UploadFootbedCDR.imageRightUrl && UploadFootbedCDR.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadFootbedCDR.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootbedCDR.imageRight)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootbedCDR.imageRight)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootbedCDR', UploadFootbedCDR.measurementFiledID, 'imageRight')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadFootbedCDR-imageBoth" @change="handleImagesUpload('UploadFootbedCDR', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadFootbedCDR.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadFootbedCDR.imageBoth && UploadFootbedCDR.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadFootbedCDR.imageBothUrl && !UploadFootbedCDR.imageBoth">{{
                    UploadFootbedCDR.imageBothUrl }}</div>
                  <div v-if="!UploadFootbedCDR.imageBothUrl && UploadFootbedCDR.imageBoth">
                    <div class="row col-md-6">
                    <img :src="urlGenerator(UploadFootbedCDR.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadFootbedCDR.imageBoth)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadFootbedCDR.imageBoth)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadFootbedCDR', UploadFootbedCDR.measurementFiledID, 'imageBoth')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadFootbedCDR')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Raw Foot 3D File -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadRawFoot3DFile" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Raw Foot 3D File') }}
                <span v-if="!arrayNull(UploadRawFoot3DFile)" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="UploadRawFoot3DFile" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadRawFoot3DFile-imageLeft"
                      @change="handleImagesUpload('UploadRawFoot3DFile', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadRawFoot3DFile.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadRawFoot3DFile.imageLeft && UploadRawFoot3DFile.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadRawFoot3DFile.imageLeftUrl && !UploadRawFoot3DFile.imageLeft">{{
                    UploadRawFoot3DFile.imageLeftUrl }}</div>
                  <div v-if="!UploadRawFoot3DFile.imageLeftUrl && UploadRawFoot3DFile.imageLeft">
                    <div class="row col-md-6">
                    <img :src="urlGenerator(UploadRawFoot3DFile.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadRawFoot3DFile.imageLeft)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadRawFoot3DFile.imageLeft)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadRawFoot3DFile', UploadRawFoot3DFile.measurementFiledID, 'imageLeft')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadRawFoot3DFile-imageRight"
                      @change="handleImagesUpload('UploadRawFoot3DFile', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadRawFoot3DFile.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadRawFoot3DFile.imageRight && UploadRawFoot3DFile.imageRightUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadRawFoot3DFile.imageRightUrl && !UploadRawFoot3DFile.imageRight">{{
                    UploadRawFoot3DFile.imageRightUrl }}</div>
                  <div v-if="!UploadRawFoot3DFile.imageRightUrl && UploadRawFoot3DFile.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadRawFoot3DFile.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm"
                          :href="urlGenerator(UploadRawFoot3DFile.imageRight)" target="_blank"><app-icon name="eye"/></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm"
                        :href="urlGenerator(UploadRawFoot3DFile.imageRight)" download target="_blank"><app-icon name="download"/></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm"
                        v-if="!isDisable" @click="() => deleteExaminatonImage('UploadRawFoot3DFile', UploadRawFoot3DFile.measurementFiledID, 'imageRight')"><app-icon name="trash-2"/></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadRawFoot3DFile-imageBoth"
                      @change="handleImagesUpload('UploadRawFoot3DFile', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadRawFoot3DFile.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadRawFoot3DFile.imageBoth && UploadRawFoot3DFile.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadRawFoot3DFile.imageBothUrl && !UploadRawFoot3DFile.imageBoth">{{
                    UploadRawFoot3DFile.imageBothUrl }}</div>
                  <div v-if="!UploadRawFoot3DFile.imageBothUrl && UploadRawFoot3DFile.imageBoth">
                    <div class="row col-md-6">
                    <img :src="urlGenerator(UploadRawFoot3DFile.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadRawFoot3DFile.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadRawFoot3DFile.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadRawFoot3DFile', UploadRawFoot3DFile.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadRawFoot3DFile')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Processed Foot 3D File -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadProcessedFoot3DFile" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Processed Foot 3D File') }}
                <span v-if="!arrayNull(UploadProcessedFoot3DFile)" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="UploadProcessedFoot3DFile" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFoot3DFile-imageLeft"
                      @change="handleImagesUpload('UploadProcessedFoot3DFile', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFoot3DFile.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFoot3DFile.imageLeft && UploadProcessedFoot3DFile.imageLeftUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFoot3DFile.imageLeftUrl && !UploadProcessedFoot3DFile.imageLeft">{{
                      UploadProcessedFoot3DFile.imageLeftUrl }}</div>
                  <div v-if="!UploadProcessedFoot3DFile.imageLeftUrl && UploadProcessedFoot3DFile.imageLeft">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadProcessedFoot3DFile.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                      </div>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFoot3DFile', UploadProcessedFoot3DFile.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                    <a class="btn btn-danger text-white"
                      @click="deleteExaminatonImage('UploadProcessedFoot3DFile', UploadProcessedFoot3DFile.measurementFiledID, 'imageLeft')">Delete
                      Image</a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFoot3DFile-imageRight"
                      @change="handleImagesUpload('UploadProcessedFoot3DFile', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFoot3DFile.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFoot3DFile.imageRight && UploadProcessedFoot3DFile.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFoot3DFile.imageRightUrl && !UploadProcessedFoot3DFile.imageRight">{{
                      UploadProcessedFoot3DFile.imageRightUrl }}</div>
                  <div v-if="!UploadProcessedFoot3DFile.imageRightUrl && UploadProcessedFoot3DFile.imageRight">
                   <div class="row col-md-6">
                    <img :src="urlGenerator(UploadProcessedFoot3DFile.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                   </div>
                   <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFoot3DFile', UploadProcessedFoot3DFile.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFoot3DFile-imageBoth"
                      @change="handleImagesUpload('UploadProcessedFoot3DFile', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFoot3DFile.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFoot3DFile.imageBoth && UploadProcessedFoot3DFile.imageBothUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFoot3DFile.imageBothUrl && !UploadProcessedFoot3DFile.imageBoth">{{
                      UploadProcessedFoot3DFile.imageBothUrl }}</div>
                  <div v-if="!UploadProcessedFoot3DFile.imageBothUrl && UploadProcessedFoot3DFile.imageBoth">
                    <div class="row col-md-6"> 
                      <img :src="urlGenerator(UploadProcessedFoot3DFile.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFoot3DFile.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFoot3DFile', UploadProcessedFoot3DFile.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>
                    <a class="btn btn-danger text-white"
                      @click="deleteExaminatonImage('UploadProcessedFoot3DFile', UploadProcessedFoot3DFile.measurementFiledID, 'imageBoth')">Delete
                      Image</a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Enter Job Dimensions</label>
                </div>
                <div class="col-md-8 mb-3">
                  <textarea class="form-control" placeholder="Enter Job Dimensions" id="job_dimensions" rows="3"
                    v-model="UploadProcessedFoot3DFile.jobDimensions"></textarea>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadProcessedFoot3DFile')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Processed Foot Tool Path -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadProcessedFootToolPath" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Processed Foot Tool Path') }}
                <span v-if="!arrayNull(UploadProcessedFootToolPath)" class="text-success ml-2"><app-icon name="check-circle"/></span>  
              </h5>
            </div>
          </div>
          <div id="UploadProcessedFootToolPath" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFootToolPath-imageLeft"
                      @change="handleImagesUpload('UploadProcessedFootToolPath', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFootToolPath.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFootToolPath.imageLeft && UploadProcessedFootToolPath.imageLeftUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFootToolPath.imageLeftUrl && !UploadProcessedFootToolPath.imageLeft">{{
                      UploadProcessedFootToolPath.imageLeftUrl }}</div>
                  <div v-if="!UploadProcessedFootToolPath.imageLeftUrl && UploadProcessedFootToolPath.imageLeft">
                    <div class="row col-md-6">
                    <img :src="urlGenerator(UploadProcessedFootToolPath.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                      <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                      <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFootToolPath', UploadRawFoot3DFile.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFootToolPath-imageRight"
                      @change="handleImagesUpload('UploadProcessedFootToolPath', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFootToolPath.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFootToolPath.imageRight && UploadProcessedFootToolPath.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFootToolPath.imageRightUrl && !UploadProcessedFootToolPath.imageRight">{{
                      UploadProcessedFootToolPath.imageRightUrl }}</div>
                  <div v-if="!UploadProcessedFootToolPath.imageRightUrl && UploadProcessedFootToolPath.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadProcessedFootToolPath.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFootToolPath', UploadProcessedFootToolPath.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadProcessedFootToolPath-imageBoth"
                      @change="handleImagesUpload('UploadProcessedFootToolPath', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadProcessedFootToolPath.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadProcessedFootToolPath.imageBoth && UploadProcessedFootToolPath.imageBothUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadProcessedFootToolPath.imageBothUrl && !UploadProcessedFootToolPath.imageBoth">{{
                      UploadProcessedFootToolPath.imageBothUrl }}</div>
                  <div v-if="!UploadProcessedFootToolPath.imageBothUrl && UploadProcessedFootToolPath.imageBoth">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadProcessedFootToolPath.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadProcessedFootToolPath.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadProcessedFootToolPath', UploadProcessedFootToolPath.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Enter Job Dimensions</label>
                </div>
                <div class="col-md-8 mb-3">
                  <textarea class="form-control" placeholder="Enter Job Dimensions" id="job_dimensions" rows="3"
                    v-model="formData.job_dimensions"></textarea>
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadProcessedFootToolPath')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Insoles 3D Files -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadInsoles3DFiles" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Insoles 3D Files') }}
              <span v-if="!arrayNull(UploadInsoles3DFiles)" class="text-success ml-2"><app-icon name="check-circle"/></span>  
              </h5>
            </div>
          </div>
          <div id="UploadInsoles3DFiles" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsoles3DFiles-imageLeft"
                      @change="handleImagesUpload('UploadInsoles3DFiles', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsoles3DFiles.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsoles3DFiles.imageLeft && UploadInsoles3DFiles.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadInsoles3DFiles.imageLeftUrl && !UploadInsoles3DFiles.imageLeft">{{
                    UploadInsoles3DFiles.imageLeftUrl }}</div>
                  <div v-if="!UploadInsoles3DFiles.imageLeftUrl && UploadInsoles3DFiles.imageLeft">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsoles3DFiles.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsoles3DFiles', UploadInsoles3DFiles.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsoles3DFiles-imageRight"
                      @change="handleImagesUpload('UploadInsoles3DFiles', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsoles3DFiles.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsoles3DFiles.imageRight && UploadInsoles3DFiles.imageRightUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadInsoles3DFiles.imageRightUrl && !UploadInsoles3DFiles.imageRight">
                    {{
                      UploadInsoles3DFiles.imageRightUrl }}</div>
                  <div v-if="!UploadInsoles3DFiles.imageRightUrl && UploadInsoles3DFiles.imageRight">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsoles3DFiles.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsoles3DFiles', UploadInsoles3DFiles.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsoles3DFiles-imageBoth"
                      @change="handleImagesUpload('UploadInsoles3DFiles', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsoles3DFiles.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsoles3DFiles.imageBoth && UploadInsoles3DFiles.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadInsoles3DFiles.imageBothUrl && !UploadInsoles3DFiles.imageBoth">{{
                    UploadInsoles3DFiles.imageBothUrl }}</div>
                  <div v-if="!UploadInsoles3DFiles.imageBothUrl && UploadInsoles3DFiles.imageBoth">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsoles3DFiles.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsoles3DFiles.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsoles3DFiles', UploadInsoles3DFiles.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadInsoles3DFiles')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Insoles Toolpath -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadInsolesToolpath" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Insoles Toolpath') }}
              <span v-if="!arrayNull(UploadInsolesToolpath)" class="text-success ml-2"><app-icon name="check-circle"/></span>  
              </h5>
            </div>
          </div>
          <div id="UploadInsolesToolpath" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsolesToolpath-imageLeft"
                      @change="handleImagesUpload('UploadInsolesToolpath', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsolesToolpath.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsolesToolpath.imageLeft && UploadInsolesToolpath.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadInsolesToolpath.imageLeftUrl && !UploadInsolesToolpath.imageLeft">
                    {{
                      UploadInsolesToolpath.imageLeftUrl }}</div>
                  <div v-if="!UploadInsolesToolpath.imageLeftUrl && UploadInsolesToolpath.imageLeft">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsolesToolpath.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsolesToolpath', UploadInsolesToolpath.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsolesToolpath-imageRight"
                      @change="handleImagesUpload('UploadInsolesToolpath', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsolesToolpath.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsolesToolpath.imageRight && UploadInsolesToolpath.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadInsolesToolpath.imageRightUrl && !UploadInsolesToolpath.imageRight">
                    {{
                      UploadInsolesToolpath.imageRightUrl }}</div>
                  <div v-if="!UploadInsolesToolpath.imageRightUrl && UploadInsolesToolpath.imageRight">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsolesToolpath.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsolesToolpath', UploadInsolesToolpath.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>

                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadInsolesToolpath-imageBoth"
                      @change="handleImagesUpload('UploadInsolesToolpath', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadInsolesToolpath.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadInsolesToolpath.imageBoth && UploadInsolesToolpath.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadInsolesToolpath.imageBothUrl && !UploadInsolesToolpath.imageBoth">
                    {{
                      UploadInsolesToolpath.imageBothUrl }}</div>
                  <div v-if="!UploadInsolesToolpath.imageBothUrl && UploadInsolesToolpath.imageBoth">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadInsolesToolpath.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadInsolesToolpath.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadInsolesToolpath', UploadInsolesToolpath.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>

                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadInsolesToolpath')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Custom Shoe Last 3D Files -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadCustomShoeLast3DFiles" aria-expanded="false"
              aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Upload Custom Shoe Last 3D Files') }}
              <span v-if="!arrayNull(UploadCustomShoeLast3DFiles)" class="text-success ml-2"><app-icon name="check-circle"/></span>  
              </h5>
            </div>
          </div>
          <div id="UploadCustomShoeLast3DFiles" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Left outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadCustomShoeLast3DFiles-imageLeft"
                      @change="handleImagesUpload('UploadCustomShoeLast3DFiles', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadCustomShoeLast3DFiles.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadCustomShoeLast3DFiles.imageLeft && UploadCustomShoeLast3DFiles.imageLeftUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadCustomShoeLast3DFiles.imageLeftUrl && !UploadCustomShoeLast3DFiles.imageLeft">{{
                      UploadCustomShoeLast3DFiles.imageLeftUrl }}</div>
                  <div v-if="!UploadCustomShoeLast3DFiles.imageLeftUrl && UploadCustomShoeLast3DFiles.imageLeft">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadCustomShoeLast3DFiles.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadCustomShoeLast3DFiles', UploadCustomShoeLast3DFiles.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Right outline</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadCustomShoeLast3DFiles-imageRight"
                      @change="handleImagesUpload('UploadCustomShoeLast3DFiles', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadCustomShoeLast3DFiles.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadCustomShoeLast3DFiles.imageRight && UploadCustomShoeLast3DFiles.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadCustomShoeLast3DFiles.imageRightUrl && !UploadCustomShoeLast3DFiles.imageRight">{{
                      UploadCustomShoeLast3DFiles.imageRightUrl }}</div>
                  <div v-if="!UploadCustomShoeLast3DFiles.imageRightUrl && UploadCustomShoeLast3DFiles.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadCustomShoeLast3DFiles.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadCustomShoeLast3DFiles', UploadCustomShoeLast3DFiles.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">Both</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadCustomShoeLast3DFiles-imageBoth"
                      @change="handleImagesUpload('UploadCustomShoeLast3DFiles', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadCustomShoeLast3DFiles.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadCustomShoeLast3DFiles.imageBoth && UploadCustomShoeLast3DFiles.imageBothUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadCustomShoeLast3DFiles.imageBothUrl && !UploadCustomShoeLast3DFiles.imageBoth">{{
                      UploadCustomShoeLast3DFiles.imageBothUrl }}</div>
                  <div v-if="!UploadCustomShoeLast3DFiles.imageBothUrl && UploadCustomShoeLast3DFiles.imageBoth">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadCustomShoeLast3DFiles.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadCustomShoeLast3DFiles.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadCustomShoeLast3DFiles', UploadCustomShoeLast3DFiles.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadCustomShoeLast3DFiles')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Upload Miscellaneous Files -->
        <div class="card">
          <div class="card-header border-0 bg-white">
            <div data-toggle="collapse" data-target="#UploadMiscellaneousFiles" aria-expanded="false" aria-controls="permission"
              class="custom-checkbox-default d-block position-relative text-capitalize collapsible-link py-2 cursor-pointer collapsed">
              <h5 class="card-title">{{ $t('Miscellaneous Files') }}
                <span v-if="!arrayNull(UploadMiscellaneousFiles)" class="text-success ml-2"><app-icon name="check-circle"/></span>
              </h5>
            </div>
          </div>
          <div id="UploadMiscellaneousFiles" data-parent="#accordionExample" class="collapse">
            <div class="card-body p-primary">
              <div class="row mb-4">
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">File 1</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadMiscellaneousFiles-imageLeft"
                      @change="handleImagesUpload('UploadMiscellaneousFiles', 'imageLeft')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadMiscellaneousFiles.imageLeftUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadMiscellaneousFiles.imageLeft && UploadMiscellaneousFiles.imageLeftUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadMiscellaneousFiles.imageLeftUrl && !UploadMiscellaneousFiles.imageLeft">
                    {{
                    UploadMiscellaneousFiles.imageLeftUrl }}</div>
                  <div v-if="!UploadMiscellaneousFiles.imageLeftUrl && UploadMiscellaneousFiles.imageLeft">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadMiscellaneousFiles.imageLeft)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageLeft)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageLeft)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadMiscellaneousFiles', UploadMiscellaneousFiles.measurementFiledID, 'imageLeft')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('leftOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">File 2</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadMiscellaneousFiles-imageRight"
                      @change="handleImagesUpload('UploadMiscellaneousFiles', 'imageRight')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadMiscellaneousFiles.imageRightUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadMiscellaneousFiles.imageRight && UploadMiscellaneousFiles.imageRightUrl" />
                  </div>
                  <div class="text-danger"
                    v-if="UploadMiscellaneousFiles.imageRightUrl && !UploadMiscellaneousFiles.imageRight">{{
                    UploadMiscellaneousFiles.imageRightUrl }}</div>
                  <div v-if="!UploadMiscellaneousFiles.imageRightUrl && UploadMiscellaneousFiles.imageRight">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadMiscellaneousFiles.imageRight)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageRight)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageRight)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadMiscellaneousFiles', UploadMiscellaneousFiles.measurementFiledID, 'imageRight')"><app-icon name="trash-2" /></a>
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('rightOutline', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">File 3</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadMiscellaneousFiles-imageBoth"
                      @change="handleImagesUpload('UploadMiscellaneousFiles', 'imageBoth')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadMiscellaneousFiles.imageBothUrl" width="200px" alt="Uploaded Image"
                      v-if="UploadMiscellaneousFiles.imageBoth && UploadMiscellaneousFiles.imageBothUrl" />
                  </div>
                  <div class="text-danger" v-if="UploadMiscellaneousFiles.imageBothUrl && !UploadMiscellaneousFiles.imageBoth">
                    {{
                    UploadMiscellaneousFiles.imageBothUrl }}</div>
                  <div v-if="!UploadMiscellaneousFiles.imageBothUrl && UploadMiscellaneousFiles.imageBoth">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadMiscellaneousFiles.imageBoth)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageBoth)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageBoth)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadMiscellaneousFiles', UploadMiscellaneousFiles.measurementFiledID, 'imageBoth')"><app-icon name="trash-2" /></a>

                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">File 4</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadMiscellaneousFiles-imageFile4"
                      @change="handleImagesUpload('UploadMiscellaneousFiles', 'imageFile4')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadMiscellaneousFiles.imageFile4Url" width="200px" alt="Uploaded Image"
                      v-if="UploadMiscellaneousFiles.imageFile4 && UploadMiscellaneousFiles.imageFile4Url" />
                  </div>
                  <div class="text-danger" v-if="UploadMiscellaneousFiles.imageFile4Url && !UploadMiscellaneousFiles.imageFile4">
                    {{
                    UploadMiscellaneousFiles.imageFile4Url }}</div>
                  <div v-if="!UploadMiscellaneousFiles.imageFile4Url && UploadMiscellaneousFiles.imageFile4">
                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadMiscellaneousFiles.imageFile4)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageFile4)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageFile4)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadMiscellaneousFiles', UploadMiscellaneousFiles.measurementFiledID, 'imageFile4')"><app-icon name="trash-2" /></a>
                    
                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
                <div class="d-flex align-items-center col-md-4 mb-3">
                  <label class="form-label">File 5</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div>
                    <!-- Input for Image Upload -->
                    <input type="file" class="form-control"
                      accept=".jpg, .jpeg, .gif, .svg, .cdr, .dxf, .pdf, .stl, .cnc, .imed, .png, .psd, .txt"
                      ref="UploadMiscellaneousFiles-imageFile5"
                      @change="handleImagesUpload('UploadMiscellaneousFiles', 'imageFile5')" />
                    <!-- Display Uploaded Image -->
                    <img :src="UploadMiscellaneousFiles.imageFile5Url" width="200px" alt="Uploaded Image"
                      v-if="UploadMiscellaneousFiles.imageFile5 && UploadMiscellaneousFiles.imageFile5Url" />
                  </div>
                  <div class="text-danger" v-if="UploadMiscellaneousFiles.imageFile5Url && !UploadMiscellaneousFiles.imageFile5">
                    {{
                    UploadMiscellaneousFiles.imageFile5Url }}</div>
                  <div v-if="!UploadMiscellaneousFiles.imageFile5Url && UploadMiscellaneousFiles.imageFile5">

                    <div class="row col-md-6">
                      <img :src="urlGenerator(UploadMiscellaneousFiles.imageFile5)"  width="200px" alt="Upload Foot Outlines PDF" />
                    </div>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageFile5)" target="_blank"><app-icon name="eye" /></a>
                    <a class="btn btn-primary text-white cursor-pointer btn-sm" :href="urlGenerator(UploadMiscellaneousFiles.imageFile5)" download target="_blank"><app-icon name="download" /></a>
                    <a class="btn btn-danger text-white cursor-pointer btn-sm" v-if="!isDisable" @click="() => deleteExaminatonImage('UploadMiscellaneousFiles', UploadMiscellaneousFiles.measurementFiledID, 'imageFile5')"><app-icon name="trash-2" /></a>

                  </div>
                  <!-- <input type="file" class="form-control" @change="handleFileUpload('bothOutlines', $event)" /> -->
                </div>
              </div>
              <div class="mt-primary">
                <button class="btn btn-primary" v-if="!isDisable" type="button" @click.prevent="saveSection('UploadMiscellaneousFiles')">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-confirmation-modal v-if="confirmationModalActive" icon="trash-2"
        modal-id="app-status-change-confirmation-modal" :message="confirmationMessage" @confirmed="lotStatusChangeConfirm"
        @cancelled="lotStatusChangeCancel" />
    </form>
  </fieldset>
</template>

<script>
import { MEASUREMENTFIELDS, MEASUREMENT, GET_MEASUREMENT, CUSTOMERS, MEASUREMENT_UNIT, MEASUREMENT_FILES, DELETE_MEASUREMENT_FILES } from "../../../../Config/ApiUrl-CPB";
import { axiosGet, axiosPost, axiosPatch, axiosDelete, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";

export default {
  mixins: [HelperMixin],
  props: {
    props: {
      customerId: {},
    },
  },
  data() {
    return {
      urlGenerator,
      measurementsUnit: '',
      formData: {},
      measurementFields: [],
      measurements: [],
      customerBranchId: null,
      UploadFootOutlinesPDF: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadFootbedCDR: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadRawFoot3DFile: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadProcessedFoot3DFile: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
        jobDimensions: null,
      },
      UploadProcessedFootToolPath: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
        jobDimensions: null,
      },
      UploadInsoles3DFiles: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadInsolesToolpath: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadCustomShoeLast3DFiles: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      UploadMiscellaneousFiles: {
        imageRight: null,
        imageRightUrl: null,
        imageLeft: null,
        imageLeftUrl: null,
        imageBoth: null,
        imageBothUrl: null,
        imageFile4: null,
        imageFile4Url: null,
        imageFile5: null,
        imageFile5Url: null,
        measurementFiledID: null,
        measurementFileError: null,
      },
      isDisable: null,
      confirmationMessage: '',
      confirmationModalActive: false,
      measurementsPrintUrl: '',
    };
  },
  created() {
    this.fetchMeasurementFields();
    this.fetchMeasurements();
    this.fetchCustomerBranchId();
    this.fetchMeasurementsFilesData(this.props[0]);
    this.isDisable = this.props[1];
    this.measurementsPrintUrl = this.urlGenerator('app/measurement/print/'+this.props[0])
  },
  methods: {
    showLoader(hide=false){
      $("div#app").addClass('apploader');
      if(hide) $("div#app").removeClass('apploader');
    },
    getLeftPlaceholder(fieldName, index) {
      if (index < this.measurementFields.length - 4) {
        return `Enter left ${fieldName} (${this.measurementsUnit || 'mm'})`;
      } else {
        return `Enter left ${fieldName}`;
      }
    },
    arrayNull(arr) {
      var keysToCheck = ["imageBoth","imageBothUrl","imageFile4","imageFile5","imageLeft","imageLeftUrl","imageRight","imageRightUrl","jobDimensions","measurementFileError"];
      return keysToCheck.every(key => arr[key] === null);
    },
    getRightPlaceholder(fieldName, index) {
      if (index < this.measurementFields.length - 4) {
        return `Enter right ${fieldName} (${this.measurementsUnit || 'mm'})`;
      } else {
        return `Enter right ${fieldName}`;
      }
    },
    deleteExaminatonImage(tableName, imageId, imageType) {
      // Set confirmation message and image details
      this.confirmationMessage = "Are you sure you want to delete this image?";
      this.imageToDelete = {
        tableName,
        imageId,
        imageType,
      };

      // Show the confirmation modal
      this.confirmationModalActive = true;
    },
    lotStatusChangeConfirm() {
      // Perform the image deletion
      const { tableName, imageId, imageType } = this.imageToDelete;
      axiosDelete(DELETE_MEASUREMENT_FILES + `${tableName}/${imageId}/${imageType}`, {
        params: { imageType: imageType },
      })
        .then((response) => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          this.toastAndReload(response.data.message);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error(error);
        });
    },
    lotStatusChangeCancel() {
      this.confirmationMessage = '';
      this.confirmationModalActive = false;
    },
    handleImagesUpload(section, imageProperty) {
      console.log(section, imageProperty);
      const fileInput = this.$refs[`${section}-${imageProperty}`];
      const allowedFileTypes = ['image/jpeg', 'image/gif', 'application/cdr', 'application/dxf', 'image/svg+xml', 'application/pdf', 'application/stl', 'application/cnc', 'application/imed', 'image/png', 'image/vnd.adobe.photoshop', 'text/plain'];
      const imagePropertyUrl = imageProperty + 'Url';
      console.log(imagePropertyUrl);

      // Check if fileInput exists and has files
      if (fileInput && fileInput.files.length > 0) {
        const imageFile = fileInput.files[0];

        // Check if the selected file type is allowed
        if (!allowedFileTypes.includes(imageFile.type)) {
          this[section][imageProperty] = null; // Clear the invalid image property
          this[section][imagePropertyUrl] = `The selected file for "${imageProperty}" is not an image (jpg, jpeg, gif, cdr, dxf, pdf, stl, cnc, imed, png, psd, txt).`;
          return; // Stop further processing
        }

        this.fileValidationError = null;

        // Set the image property and its URL dynamically
        this[section][imageProperty] = imageFile;
        console.log('F', this[section][imageProperty]);

        // Generate the image URL for display
        this[section][`${imageProperty}Url`] = URL.createObjectURL(imageFile);
      } else {
        // Clear the image property and URL if no file is selected
        this[section][imageProperty] = null;
        this[section][`${imageProperty}Url`] = null;
        this.fileValidationError = null;
      }
    },
    fetchCustomerBranchId() {
      axiosGet(`${CUSTOMERS}/${this.props[0]}`).then(response => {
        this.customerBranchId = response.data.branch_id;
        console.log('F', this.customerBranchId);

        // Call loadMeasurementsUnit with customerBranchId as branchId
        this.loadMeasurementsUnit(this.customerBranchId);

      }).catch(error => {
        this.$toastr.e(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fetchMeasurementsFilesData(customerId) {
      axiosGet(`${MEASUREMENT_FILES}/${customerId}`)
        .then(response => {
          // Assuming the response.data contains the MeasurementsFiles data for the customer
          const measurementsFiles = response.data;

          // Create an object to store image URLs for all sections
          const sectionImages = {};

          // Iterate through the measurementsFiles and update the sectionImages object
          measurementsFiles.forEach(file => {
            sectionImages[file.section] = {
              imageLeft: file.image_left,
              imageRight: file.image_right,
              imageBoth: file.image_both,
              imageFile4: file.file4,
              imageFile5: file.file5,
              jobDimensions: file.job_dimensions,
              measurementFiledID: file.id,
            };
          });

          // Define an array of section names
          const sections = [
            'UploadFootOutlinesPDF',
            'UploadFootbedCDR',
            'UploadRawFoot3DFile',
            'UploadProcessedFoot3DFile',
            'UploadProcessedFootToolPath',
            'UploadInsoles3DFiles',
            'UploadInsolesToolpath',
            'UploadCustomShoeLast3DFiles',
            'UploadMiscellaneousFiles',
          ];

          // Iterate through the sections and set image URLs for each section
          sections.forEach(sectionName => {
            this[sectionName].imageLeft = sectionImages[sectionName]?.imageLeft || null;
            this[sectionName].imageRight = sectionImages[sectionName]?.imageRight || null;
            this[sectionName].imageBoth = sectionImages[sectionName]?.imageBoth || null;
            this[sectionName].imageFile4 = sectionImages[sectionName]?.imageFile4 || null;
            this[sectionName].imageFile5 = sectionImages[sectionName]?.imageFile5 || null;
            this[sectionName].jobDimensions = sectionImages[sectionName]?.jobDimensions || null;
            this[sectionName].measurementFiledID = sectionImages[sectionName]?.measurementFiledID || null;
          });
        })
        .catch(error => {
          console.error(error);
          // Handle the error if needed
        });
    },
    saveSection(section) {
      const measurementsFilesData = new FormData();
      measurementsFilesData.append("customer_id", this.props[0]);
      measurementsFilesData.append("section", section);
      if (this[section].imageLeft) {
        measurementsFilesData.append("image_left", this[section].imageLeft);
      }
      if (this[section].imageRight) {
        measurementsFilesData.append("image_right", this[section].imageRight);
      }
      if (this[section].imageBoth) {
        measurementsFilesData.append("image_both", this[section].imageBoth);
      }
      if (this[section].imageFile4) {
        measurementsFilesData.append("file4", this[section].imageFile4);
      }
      if (this[section].imageFile5) {
        measurementsFilesData.append("file5", this[section].imageFile5);
      }
      measurementsFilesData.append("job_dimensions", this[section].jobDimensions ? this[section].jobDimensions : '');


      // Perform insert or update
      if (this[section].measurementFiledID) {
        this.updateMeasurementsFilesData(this[section].measurementFiledID, measurementsFilesData);
      } else {
        this.insertMeasurementsFilesData(measurementsFilesData);
      }
    },
    // Method to insert Measurements Files data
    insertMeasurementsFilesData(measurementsFilesData) {
      this.showLoader();

      // Assuming you have an API endpoint to handle Measurements Files data insertion
      axiosPost(`${MEASUREMENT_FILES}`, measurementsFilesData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.showLoader(true);
        this.fetchMeasurementsFilesData(this.props[0]);
        this.UploadFootOutlinesPDF.imageLeftUrl = null;
        this.UploadFootOutlinesPDF.imageRightUrl = null;
        this.UploadFootOutlinesPDF.imageBothUrl = null;
        
        this.UploadFootbedCDR.imageLeftUrl = null;
        this.UploadFootbedCDR.imageRightUrl = null;
        this.UploadFootbedCDR.imageBothUrl = null;
        
        this.UploadRawFoot3DFile.imageLeftUrl = null;
        this.UploadRawFoot3DFile.imageRightUrl = null;
        this.UploadRawFoot3DFile.imageBothUrl = null;
        
        this.UploadProcessedFoot3DFile.imageLeftUrl = null;
        this.UploadProcessedFoot3DFile.imageRightUrl = null;
        this.UploadProcessedFoot3DFile.imageBothUrl = null;
        
        this.UploadProcessedFootToolPath.imageLeftUrl = null;
        this.UploadProcessedFootToolPath.imageRightUrl = null;
        this.UploadProcessedFootToolPath.imageBothUrl = null;
        
        this.UploadInsoles3DFiles.imageLeftUrl = null;
        this.UploadInsoles3DFiles.imageRightUrl = null;
        this.UploadInsoles3DFiles.imageBothUrl = null;

        this.UploadInsolesToolpath.imageLeftUrl = null;
        this.UploadInsolesToolpath.imageRightUrl = null;
        this.UploadInsolesToolpath.imageBothUrl = null;

        this.UploadCustomShoeLast3DFiles.imageLeftUrl = null;
        this.UploadCustomShoeLast3DFiles.imageRightUrl = null;
        this.UploadCustomShoeLast3DFiles.imageBothUrl = null;

        this.UploadMiscellaneousFiles.imageLeftUrl = null;
        this.UploadMiscellaneousFiles.imageRightUrl = null;
        this.UploadMiscellaneousFiles.imageBothUrl = null;
        this.UploadMiscellaneousFiles.imageFile4Url = null;
        this.UploadMiscellaneousFiles.imageFile5Url = null;
        this.toastAndReload(response.data.message);
        // console.log(response.data.message);
        // Do something after successful data insertion, if needed
      })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.message);
        });
    },
    // Method to insert Measurements Files data
    updateMeasurementsFilesData(measurementFiledID, measurementsFilesData) {
      this.showLoader();

      // Assuming you have an API endpoint to handle Measurements Files data insertion
      axiosPost(`${MEASUREMENT_FILES}/${measurementFiledID}`, measurementsFilesData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.showLoader(true);
        this.fetchMeasurementsFilesData(this.props[0]);
        this.UploadFootOutlinesPDF.imageLeftUrl = null;
        this.UploadFootOutlinesPDF.imageRightUrl = null;
        this.UploadFootOutlinesPDF.imageBothUrl = null;
        
        this.UploadFootbedCDR.imageLeftUrl = null;
        this.UploadFootbedCDR.imageRightUrl = null;
        this.UploadFootbedCDR.imageBothUrl = null;
        
        this.UploadRawFoot3DFile.imageLeftUrl = null;
        this.UploadRawFoot3DFile.imageRightUrl = null;
        this.UploadRawFoot3DFile.imageBothUrl = null;
        
        this.UploadProcessedFoot3DFile.imageLeftUrl = null;
        this.UploadProcessedFoot3DFile.imageRightUrl = null;
        this.UploadProcessedFoot3DFile.imageBothUrl = null;
        
        this.UploadProcessedFootToolPath.imageLeftUrl = null;
        this.UploadProcessedFootToolPath.imageRightUrl = null;
        this.UploadProcessedFootToolPath.imageBothUrl = null;
        
        this.UploadInsoles3DFiles.imageLeftUrl = null;
        this.UploadInsoles3DFiles.imageRightUrl = null;
        this.UploadInsoles3DFiles.imageBothUrl = null;

        this.UploadInsolesToolpath.imageLeftUrl = null;
        this.UploadInsolesToolpath.imageRightUrl = null;
        this.UploadInsolesToolpath.imageBothUrl = null;

        this.UploadCustomShoeLast3DFiles.imageLeftUrl = null;
        this.UploadCustomShoeLast3DFiles.imageRightUrl = null;
        this.UploadCustomShoeLast3DFiles.imageBothUrl = null;

        this.UploadMiscellaneousFiles.imageLeftUrl = null;
        this.UploadMiscellaneousFiles.imageRightUrl = null;
        this.UploadMiscellaneousFiles.imageBothUrl = null;
        this.UploadMiscellaneousFiles.imageFile4Url = null;
        this.UploadMiscellaneousFiles.imageFile5Url = null;
        
        this.toastAndReload(response.data.message);
        // console.log(response.data.message);
        // Do something after successful data insertion, if needed
      })
        .catch(error => {
          this.showLoader(true);
          this.$toastr.e(error.response.data.message);
        });
    },
    loadMeasurementsUnit(branchId) {
      axiosGet(`${MEASUREMENT_UNIT}/${branchId}`).then(response => {
        console.log('response:', response.data.measurements_unit);
        const measurementsUnit = response.data.measurements_unit;

        // Set the measurementsUnit value in the data
        this.measurementsUnit = measurementsUnit;
      }).catch(error => {
        console.error(error);
      });
    },
    fetchMeasurementFields() {
      const branchId = this.formData.branch_id;
      console.log('Id', branchId);
      axiosGet(`${MEASUREMENTFIELDS}`)
        .then(response => {
          this.measurementFields = response.data;
          this.initializeFormData();
        })
        .catch(error => {
          console.error('Error fetching measurement fields:', error);
        });
    },
    fetchMeasurements() {
      axiosGet(`${GET_MEASUREMENT}/${this.props[0]}`)
        .then(response => {
          this.measurements = response.data;
          // console.log('Fetched measurements:', this.measurements); // Check the console for the fetched measurements
          this.populateFormData();
        })
        .catch(error => {
          console.error('Error fetching measurements:', error);
        });
    },
    initializeFormData() {
      // Initialize formData with null values for each measurement field
      this.measurementFields.forEach(field => {
        const fieldId = field.id;
        this.$set(this.formData, `left_${fieldId}`, null);
        this.$set(this.formData, `right_${fieldId}`, null);
      });
    },
    populateFormData() {
      // Populate formData with existing measurements
      this.measurements.forEach(measurement => {
        const fieldId = measurement.measurement_fields_id;
        const leftValue = parseFloat(measurement.left_value); // Convert to number
        const rightValue = parseFloat(measurement.right_value); // Convert to number

        this.$set(this.formData, `left_${fieldId}`, leftValue);
        this.$set(this.formData, `right_${fieldId}`, rightValue);
      });
    },
    submitForm() {
      // Prepare the data to send to the backend
      const data = {
        customer_id: this.props[0],
        measurements: this.prepareMeasurementData(),
      };

      // Check if there are existing measurements for the customer
      if (this.measurements.length > 0) {
        // If there are existing measurements, update them using axiosPatch (PUT)
        axiosPost(`${MEASUREMENT}/${this.props[0]}`, data) // Use axiosPatch for updating (PUT)
          .then(response => {
            this.toastAndReload(response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
            // You can perform any additional actions or show a success message here
          })
          .catch(error => {
            // Handle the error if the data couldn't be updated
            // You can show an error message or perform any other actions here
            console.error('Error:', error);
          });
      } else {
        // If there are no existing measurements, create new measurements using axiosPost (POST)
        axiosPost(`${MEASUREMENT}`, data) // Use axiosPost for creating (POST)
          .then(response => {
            this.toastAndReload(response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
            // You can perform any additional actions or show a success message here
          })
          .catch(error => {
            // Handle the error if the data couldn't be saved
            // You can show an error message or perform any other actions here
            console.error('Error:', error);
          });
      }
    },
    prepareMeasurementData() {
      // Prepare the measurements data from the formData
      const measurements = [];
      this.measurementFields.forEach(field => {
        const fieldId = field.id;
        const leftValue = this.formData[`left_${fieldId}`];
        const rightValue = this.formData[`right_${fieldId}`];

        // Check if both left and right values are not null before pushing to measurements array
        if (leftValue !== null || rightValue !== null) {
          measurements.push({
            field_id: fieldId,
            left_value: leftValue,
            right_value: rightValue,
          });
        }
      });
      return measurements;
    },
  },
};
</script>
<style lang="scss" scoped>
fieldset:disabled a.btn {
  pointer-events: all;
}
</style>