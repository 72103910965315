<template>
    <fieldset :disabled="isDisable">
        <div class="row">
            <div class="col-6">
                <div class="mt-primary">
                    <div class="form-check mb-4">
                        <input type="checkbox" class="form-check-input" id="notesAddToReport" v-model="notesAddToReport">
                        <label class="form-check-label" for="notesAddToReport">{{ $t('Add to Report') }}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 my-2 text-right" v-if="notesPrintUrl != ''">
                    <a :href="notesPrintUrl" target="_blank" class="btn btn-primary">Print</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-4">
                <div v-for="(note, index) in notes" :key="index">
                    <label class="col-md-6">{{ getLabelForType(note.type) }}</label>
                    <small class="col-md-6 float-right text-right font-weight-bold">
                        {{ note.notes_date ? note.notes_date : formData.notes_date }} | {{ note.user_name ? note.user_name :
                            currentUserName
                        }}
                        </small>
                    <button class="btn default-base-color float-right" v-if="note.isNew && !isDisable" @click="removeNote(index)">
                        <app-icon class="size-19 primary-text-color" name="trash" />
                    </button>
                    <textarea class="mb-4" v-model="note.content" :placeholder="getPlaceholderForType(note.type)"
                        rows="4"></textarea>
                </div>
                <app-form-group-selectable type="multi-create" :disabled="isDisable" :label="$t('Pathologies')"
                    list-value-field="chief_complaint_name" v-model="formData.pathologies"
                    :error-message="$errorMessage(errors, 'pathologies')" :fetch-url="SELECTABLE_PATHOLOGIES"
                    :store-data="pathologiesStore" @list="notesPathologies = $event" @input="chipChange" />
            </div>
            <div class="col-md-12 mb-4" v-if="!isDisable">
                <label class="col-md-11"><b>Add Note:</b></label>
                <select class="col-md-11" v-model="selectedType">
                    <option v-for="option in dropdownOptions" :key="option.value" :value="option.value">{{ option.label }}
                    </option>
                </select>
                <button class="btn default-base-color float-right" type="button" @click="addNewNote">
                    <app-icon class="size-19 primary-text-color" name="plus" />
                </button>
            </div>
            <div class="mt-primary col-md-12 mb-4" v-if="!isDisable">
                <button class="btn btn-primary" type="button" :disabled="disableSave" @click="saveNotes">{{ $t('save') }}</button>
            </div>
        </div>
    </fieldset>
</template>

<script>

import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import { axiosGet, axiosPost, axiosDelete, urlGenerator } from "../../../../../common/Helper/AxiosHelper";
import { NOTES, DELETE_NOTES } from "../../../../Config/ApiUrl-CPB";
import { SELECTABLE_PATHOLOGIES } from "../../../../Config/ApiUrl-CP";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import moment from 'moment'

export default {
    mixins: [FormHelperMixins, HelperMixin],
    props: {
        props: {
            customerId: {},
        },
    },
    data() {
        return {
            SELECTABLE_PATHOLOGIES,
            notesPathologies: [],
            formData: {
                pathologies: [],
                note_pathologies: [],
                chips: {},
                user_id: null,
                notes_date: null,
            },
            currentDate: "",
            currentUserName: "",
            notes: [
                { type: "complaint", content: "", isNew: false, id: null },
                { type: "diagnosis", content: "", isNew: false, id: null },
                { type: "prescription", content: "", isNew: false, id: null },
                // { type: "pathologies", content: "", isNew: false, id: null },
            ],
            dropdownOptions: [
                { label: "Complaint", value: "complaint" },
                { label: "Diagnosis", value: "diagnosis" },
                { label: "Prescription", value: "prescription" },
                // { label: "Pathologies", value: "pathologies" },
                { label: "Follow up", value: "follow_up" },
                { label: "Feedback", value: "feedback" },
                { label: "Idea", value: "idea" },
            ],
            selectedType: "complaint",
            isDisable: null,
            disableSave: false,
            notesAddToReport: false,
            notesPrintUrl: '',
        };
    },
    created() {
        this.fetchNotes();
        this.isDisable = this.props[1];
        this.notesPrintUrl = this.urlGenerator('app/notes/print/'+this.props[0])
        if (this.formData.notes_date === null && this.formData.user_id === null) {
            // Set the current date and user's name
            this.formData.notes_date = this.getCurrentDate();
            this.formData.user_id = this.getCurrentUserID();
        }

    },
    methods: {
        // Simulate fetching the current date from a function
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1; // Note: month is zero-based
            let day = today.getDate();

            // Pad month and day with leading zeroes if necessary
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }

            return `${day}-${month}-${year}`;
        },

        // Simulate fetching the current user's name from a function or authentication state
        getCurrentUserID() {
            const currentLoggedInUser = window.user.id;
            this.getCurrentUserName(currentLoggedInUser);
            return currentLoggedInUser;
        },
        fetchNotes() {
            axiosGet(`${NOTES}/${this.props[0]}`)
                .then((response) => {
                    const existingNotes = response.data.notes;
                    const existingPathologies = response.data.pathologies;
                    const existingFormData = response.data.formData;
                    this.notesAddToReport = response.data.add_to_report;

                    // this.formData.notes_date = moment(existingFormData[0].notes_date).format('DD-MM-YYYY');
                    // this.formData.user_id = existingFormData[0].user_id;

                    // Call getCurrentUserName with this.formData.user_id as user_id
                    this.getCurrentUserName(this.formData.user_id);

                    // Clear the existing pathologies in formData before populating
                    this.formData.pathologies.splice(0, this.formData.pathologies.length);

                    // Push the pathologies from the API response to formData.pathologies
                    this.formData.pathologies.push(...existingPathologies);

                    const noteTypes = ["complaint", "diagnosis", "prescription", "follow_up", "feedback", "idea"];

                    // Group the existing notes by their type
                    const groupedNotes = {};
                    for (const type of noteTypes) {
                        groupedNotes[type] = existingNotes.filter((note) => note.type === type);
                    }

                    // Clear the existing notes array before populating with fetched notes
                    this.notes.splice(0, this.notes.length);

                    // Create textareas for each group of notes
                    for (const type of noteTypes) {
                        const notesForType = groupedNotes[type];
                        if (notesForType.length > 0) {
                            // for (const note of notesForType) {
                            //     this.notes.push({
                            //         type: note.type,
                            //         content: note.content,
                            //         isNew: true,
                            //         id: note.id,
                            //         notes_date: note.notes_date,
                            //         user_id: note.user_id,
                            //         user_name: note.user_name,
                            //     });
                            // }
                        } else if (["complaint", "diagnosis", "prescription"].includes(type)) {
                            // If no existing notes for this type and it's one of the initial three, create a new textarea
                            this.notes.push({ type: type, content: "", isNew: false, id: null });
                        }
                    }
                    if(existingNotes.length > 0) {
                        for (const note of existingNotes) {
                            if(noteTypes.includes(note.type)) {
                                this.notes.push({
                                    type: note.type,
                                    content: note.content,
                                    isNew: true,
                                    id: note.id,
                                    notes_date: note.notes_date,
                                    user_id: note.user_id,
                                    user_name: note.user_name,
                                });
                            }
                        }
                    }

                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getCurrentUserName(userID) {
            axiosGet(`app/selectable/users/${userID}`).then(response => {
                console.log(response.data.data);
                this.currentUserName = response.data.data[0].full_name;
            }).catch(error => {
                console.error(error);
            });
        },
        addNewNote() {
            const existingNote = this.notes.find((note) => note.type === this.selectedType);
            if (!existingNote) {
                this.notes.push({ type: this.selectedType, content: "", isNew: true, id: null });
            } else {
                console.log("Adding a new one...");
                // You can choose to handle this case as needed, like showing an error message to the user.
                // For now, we're adding a new note even if a note with the selected type exists.
                this.notes.push({ type: this.selectedType, content: "", isNew: true, id: null });
            }
        },

        removeNote(index) {
            const noteId = this.notes[index].id;
            console.log(noteId);
            if (noteId == null) {
                this.notes.splice(index, 1);
            } else {
                // If it's an existing note, send a DELETE request to delete it.
                axiosDelete(`${DELETE_NOTES}/${noteId}`)
                    .then(response => {
                        // Handle successful deletion on the client-side.
                        this.notes.splice(index, 1);
                        this.toastAndReload(response.data.message);
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                        console.log('response:', response);
                        // ...
                    })
                    .catch(error => {
                        // Handle error, if any.
                        console.error('Error deleting note:', error);
                    });
            }
        },

        saveNotes() {
            this.disableSave = true;
            // Prepare the request payload with the notes and pathologies
            const requestData = {
                notes_date: this.formData.notes_date,
                user_id: this.formData.user_id,
                notes: this.notes,
                add_to_report: this.notesAddToReport,
                pathologies: this.formData.pathologies.length > 0 ? this.formData.pathologies : [], // Use an empty array if no pathologies selected
            };


            // Remove any `0` values from the pathologies array
            requestData.pathologies = requestData.pathologies.filter(item => item !== 0);

            axiosPost(`${NOTES}/${this.props[0]}`, requestData)
                .then((response) => {
                    console.log(response.data);
                    this.toastAndReload(response.data.message);
                    setTimeout(() => {
                        this.redirectToView();
                    }, 1000)
                    // Handle success or show a success message to the user
                })
                .catch((error) => {
                    console.error(error);
                    this.disableSave = false;
                    // Handle error or show an error message to the user
                });
        },
        redirectToView(){
            let customParam = '';
            let tabValue = this.getQueryStringValue('tab');
            if(tabValue != '') {
                customParam = '?tab='+tabValue;
                window.location.href = urlGenerator(`customer/view/${this.props[0]}${customParam}`);
            }
        },
        getLabelForType(type) {
            // Define labels for each note type here
            const labels = {
                complaint: "Complaint:",
                diagnosis: "Diagnosis:",
                prescription: "Prescription:",
                // pathologies: "Pathologies:",
                follow_up: "Follow up:",
                feedback: "Feedback:",
                idea: "Idea:",
            };
            return labels[type] || "Notes:";
        },
        getPlaceholderForType(type) {
            // Define placeholders for each note type here
            const placeholders = {
                complaint: "Enter complaint here...",
                diagnosis: "Enter diagnosis here...",
                prescription: "Enter prescription here...",
                // pathologies: "Enter pathologies here...",
                follow_up: "Enter follow up here...",
                feedback: "Enter feedback here...",
                idea: "Enter idea here...",
            };
            return placeholders[type] || "Enter note here...";
        },
        chipChange(pathologies) {
            const tem = this.notesPathologies.filter(({ id }) => pathologies.includes(id)).map(e => e.pathology_value);
            this.formData.note_pathologies = tem;
        },
        pathologiesStore(pathologiesName) {
            let tempId = this.notesPathologies.length ? this.notesPathologies[this.notesPathologies.length - 1].id + 1 : Date.now();

            this.notesPathologies.push({
                id: tempId,
                pathology_value: pathologiesName
            })

            this.formData.pathologies.push(tempId);

            this.formData.note_pathologies.push(pathologiesName);

        }
    },
};
</script>
<style lang="scss" scoped>
fieldset:disabled a.btn {
  pointer-events: all;
}
</style>
