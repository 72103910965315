<template>
    <app-modal
        modal-id="orders-view-modal"
        modal-size="extra-large"
        modal-alignment="top"
        @close-modal="closeModal"
    >
        <!-- Modal header -->
        <template slot="header">
            <h5 class="modal-heading">
                {{ $t('View Order') }}
            </h5>

            <button class="close outline-none" @click.prevent="closeModal">
                <app-icon name="x"/>
            </button>
        </template>

        <!-- Modal body -->
        <template slot="body">

            <div class="row">
                <div class="col-md-5 row">
                    <div class="col-md-6">{{ $t('date') }} :</div>
                    <div class="col-md-6">{{ formatDateTimeToLocal(invoice.created_at) }}</div>
                    <div class="col-md-6">{{ $t('Workshop') }} :</div>
                    <div class="col-md-6">
                        <template v-if="invoice.branch_or_warehouse">
                            {{ workshopName }}
                        </template>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5 row">
                    <div class="col-md-6">{{ $t('Order number') }} :</div>
                    <div class="col-md-6">{{ invoice.invoice_number }}</div>
                    <div class="col-md-6">{{ $t('status') }} :</div>
                    <div class="col-md-6">{{ $t(invoice.status?.name) }}</div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-md-5 row">
                    <div class="col-md-6">{{ $t('invoice_to') }} :</div>
                    <div class="col-md-6">{{ invoice.customer?.full_name }}</div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5 row">
                    <div class="col-md-6">{{ $t('Practitioner') }} :</div>
                    <div class="col-md-6">{{ invoice.customer?.users?.full_name }}</div>
                    <div class="col-md-6">{{ $t('Branch') }} :</div>
                    <div class="col-md-6">{{ invoice.branch_or_warehouse?.name }}</div>
                </div>
            </div>

            <div class="mb-4 mt-4 row no-gutters">
                <div class="labels col-12 row border-bottom no-gutters">
                    <p :class="displayDiscount == true ?'text-muted col-md-1':'text-muted col-md-2'">#</p>
                    <p class="text-muted col-md-2">{{ $t('items') }}</p>
                    <p :class="displayDiscount == true ?'text-muted col-md-1':'text-muted col-md-2'">{{ $t('quantity') }}</p>
                    <p class="text-muted col-md-2">{{ $t('unit_price') }}</p>
                    <p class="text-muted col-md-2" v-if="displayDiscount">{{ $t('unit_discount') }}</p>
                    <p class="text-muted col-md-2">{{ $t('tax') }}</p>
                    <p class="text-muted col-md-2 text-right">{{ $t('total') }}</p>
                </div>

                <div
                    class="values row no-gutters align-items-baseline justify-content-between col-md-12 border-bottom py-3"
                    v-for="(item, index) in invoice.order_products"
                    :key="item.invoice_number">
                    <p :class="displayDiscount == true ?'col-md-1':'col-md-2'"> {{ index + 1 }} </p>
                    <div class="d-flex align-items-center col-md-2">
                        <div>
                            <template v-if="item.variant">
                                <a> {{ item.variant.name }} </a>
                                <small class="d-block text-muted">{{ item.variant.upc }}</small>
                            </template>
                        </div>
                    </div>
                    <p :class="displayDiscount == true ?'col-md-1':'col-md-2'">
                        {{ item.quantity }}
                        <template v-if="item.variant">
                            <template v-if="item.variant.product">
                                <template v-if="item.variant.product.unit">
                                    ({{ item.variant.product.unit.name }})
                                </template>
                            </template>
                        </template>
                    </p>
                    <p class="col-md-2"> {{ numberWithCurrencySymbol(item.price) }} </p>
                    <p class="col-md-2" v-if="displayDiscount"> {{ item.discount_type === 'percentage' ? (item.discount_value+'%') : numberWithCurrencySymbol(item.discount_amount)}} </p>
                    <p class="col-md-2"> {{ item.tax_amount }}% </p>
                    <p class="col-md-2 text-right"> {{ numberWithCurrencySymbol(item.sub_total) }} </p>
                </div>
                <!-- <div class="col-md-6 row mt-4">
                    <div class="col-md-6"><h5>{{ $t('payment_info') }} :</h5>
                        <template v-if="invoice.payment_method">
                            <br> <span>{{ invoice.payment_method.name }}</span>
                        </template>
                    </div>

                </div> -->
                <div class="col-md-7 row mt-4">
                        <div class="col-md-12" v-if="invoice.due_history">
                            <h5>{{ $t('Due Payment Info') }} :</h5>
                            <table width="100%" style="font-size: 12px;">
                                <thead>
                                    <tr>
                                        <th width="25%">Date</th>
                                        <th width="20%">Amount</th>
                                        <th width="25%">Payment Mode</th>
                                        <th width="30%">Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in invoice.due_history" :key="item.id">
                                        <td>{{ formatDateTimeToLocal(item.created_at) }}</td>
                                        <td>{{ item.paid_amount }}</td>
                                        <td>{{ item.payment_mode.item_label }}</td>
                                        <td>{{ item.payment_note }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 mt-3" v-if="invoice.reversed_history?.length > 0">
                            <h5>{{ $t('Reversed Payment Info') }} :</h5>
                            <table width="100%" style="font-size: 12px;">
                                <thead>
                                    <tr>
                                        <th width="25%">Date</th>
                                        <th width="20%">Amount</th>
                                        <th width="25%">Payment Mode</th>
                                        <th width="30%">Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in invoice.reversed_history" :key="item.id">
                                        <td>{{ formatDateTimeToLocal(item.reversed_payment_date) }}</td>
                                        <td>{{ item.paid_amount }}</td>
                                        <td>{{ item.payment_mode.item_label }}</td>
                                        <td>{{ item.payment_note }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                <div class="col-md-5 text-right row  mt-4">
                    <div class="col-md-6">
                        {{ $t('sub_total') }}
                    </div>
                    <div class="col-md-6 text-right">
                        {{ numberWithCurrencySymbol(invoice.sub_total) }}
                    </div>
                    <div class="col-md-6" v-if="displayDiscount">
                        {{ $t('discount_on_total') }} <span v-if="invoice.discount_type === 'percentage'">[ -{{invoice.discount}} %]</span>
                    </div>
                    <div class="col-md-6 text-right" v-if="displayDiscount">
                        {{ numberWithCurrencySymbol(invoice.discount_value) }}
                    </div>
                    <div class="col-md-6">
                        {{ $t('tax') }} <span v-if="invoice?.tax?.percentage">[ +{{invoice.tax.percentage}} %]</span>
                    </div>
                    <div class="col-md-6 text-right">
                        {{ numberWithCurrencySymbol(invoice.total_tax) }}
                    </div>
                    <div class="col-md-6 border-top py-2">
                        {{ $t('invoice_total') }}
                    </div>
                    <div class="col-md-6 text-right border-top  py-2">
                        {{ numberWithCurrencySymbol(invoice.grand_total) }}
                    </div>
                    <div class="col-md-6 text-right border-top">
                        {{ $t('paid') }}
                    </div>
                    <div class="col-md-6 text-right border-top">
                        {{ numberWithCurrencySymbol(invoice.paid_amount) }}
                    </div>
                    <!-- <div class="col-md-6">
                        {{ $t('change') }}
                    </div>
                    <div class="col-md-6 text-right">
                        {{ numberWithCurrencySymbol(invoice.change_return) }}
                    </div> -->
                    <div class="col-md-6">
                        {{ $t('Balance') }}
                    </div>
                    <div class="col-md-6 text-right">
                        {{ numberWithCurrencySymbol(invoice.due_amount) }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center my-3">
                    <h3>Order Details</h3>
                </div>
                <div class="col-4">
                    <label class="form-label"><b>Technician 1 :- </b>{{invoice?.technician_one?.technician_name}}</label>
                </div>
                <div class="col-4">
                    <label class="form-label"><b>Technician 2 :- </b>{{invoice?.technician_two?.technician_name}}</label>
                </div>
                <div class="col-4">
                    <label class="form-label"><b>Quality Check :- </b>{{invoice.quality_check?.item_label}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center my-3">
                    <h3>Delivery Details</h3>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Target Date :- </b> {{ (invoice.target_date)?formatDateTimeToLocal(invoice.target_date):'' }} </label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Delivery Mode :- </b>{{ invoice.delivery_mode }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Ready Date :- </b>{{ (invoice.ready_date)?formatDateTimeToLocal(invoice.ready_date):'' }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Patient Informed? :- </b>{{ invoice.patient_informed }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Pickup Date :- </b>{{ (invoice.pickup_date)?formatDateTimeToLocal(invoice.pickup_date):'' }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Pickup Person :- </b>{{ invoice.pickup_person }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Dispatch Date :- </b>{{ (invoice.dispatch_date)?formatDateTimeToLocal(invoice.dispatch_date):'' }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Tracking Info :- </b>{{ invoice.tracking_info }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center my-3">
                    <h3>Customer Items Retained</h3>
                </div>
                <div class="col-3">
                    <p v-html="renderWithLineBreaks(invoice.customer_items_retained)"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center my-3">
                    <h3>For Workshop Use</h3>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Locker IN :- </b>{{ invoice.lockerin }}</label>
                </div>
                <div class="col-3">
                    <label class="form-label"><b>Locker OUT :- </b>{{ invoice.lockerout }}</label>
                </div>
                <div class="col-12 mt-3">
                    <label class="form-label"><b>Workshop Notes :- </b></label>
                    <p>{{ invoice.workshop_note }}</p>
                </div>
            </div>
        </template>


        <!-- Modal Footer -->
        <template slot="footer" class="justify-content-start">
            <!-- <button class="btn btn-primary mr-3" :label="$t('print')" @click="handlePrintBtnClick">{{
                    $t('print')
                }}
            </button> -->
            <app-cancel-button btn-class="btn-secondary" :label="$t('close')" @click="closeModal"/>
        </template>

        <PrintAria v-show="false" v-if="printAria" id="tharmal-80mm" css="css/invoice/test.css" @close="handlePrintAreaCloseEvent">
            <div v-html="purify(invoiceTemplateToPrint)"></div>
        </PrintAria>
    </app-modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import {DUE_PAYMENT_INFO, DUE_PAYMENT_RECEIVE, INVOICE_VIEW, GENERATE_INVOICE} from "../../../../Config/ApiUrl-CP";
import {purify} from "../../../../Helper/Purifier/HTMLPurifyHelper";
import PrintAria from "../../../../../common/Components/Helper/PrintAria";
import {formatDateTimeToLocal, numberWithCurrencySymbol} from "../../../../Helper/Helper";

export default {
    name: "OrdersViewModal",
    mixins: [FormHelperMixins],
    components: {PrintAria},
    props: {
        orderId: {
            require: true
        },
    },
    data() {
        return {
            purify,
            invoiceTemplateToPrint: '',
            workshopName: '',
            printAria: false,
            showNote: true,
            loading: false,
            displayDiscount: false,
            DUE_PAYMENT_RECEIVE,
            DUE_PAYMENT_INFO,
            invoice: {},
            invoiceData: {
                logo_source: 'https://media.glassdoor.com/sqll/4308684/gain-solutions-squarelogo-1608634285110.png',
                company_address: 'Nirala',
                company_phone: '01717605715',
                company_email: 'shishir@gain.media',
                cash_counter: '1'
            },
        }
    },
    methods: {
        numberWithCurrencySymbol,
        formatDateTimeToLocal,
        renderWithLineBreaks(text){
            return text ? text.replace(/\n/g, "<br>") : text;
        },
        handlePrintAreaCloseEvent() {
            this.printAria = false;
        },
        prepareInvoice() {
            for (const key in this.invoiceData) {
                this.assainValue(key, this.invoiceData[key])
            }
        },
        assainValue(key, value) {
            this.invoiceTemplateToPrint = this.invoiceTemplateToPrint.replace(`{${key}}`, value)
        },
        handlePrintBtnClick() {
            axiosGet(GENERATE_INVOICE + this.orderId)
                .then(res => this.invoiceTemplateToPrint = res.data.invoice_template)
                .catch(e => {
                    this.$toastr.e(e)})
                .finally(() => {
                    this.printAria = true; // temporary
                    this.prepareInvoice()
                })
        },
        getOrderInformation() {
            axiosGet(`${INVOICE_VIEW}/${this.orderId}`).then(response => {
                this.invoice = response.data
                if(response.data.discount_value > 0) {
                    this.displayDiscount = true;
                }

                var workshopNameTemp = '';
                this.invoice.order_products.some(function(item) {
                    if(item.stock_deduction_type == 2 && item.branch_or_warehouse.workshop != null) {
                        workshopNameTemp = item.branch_or_warehouse?.workshop?.name;
                    } else {
                        workshopNameTemp = item.branch_or_warehouse.name;
                    }
                    return item.stock_deduction_type === 2;
                });
                this.workshopName = workshopNameTemp;

                if(!this.displayDiscount) {
                    for (let item of response.data.order_products) {
                        if(item.discount_value > 0) {
                            this.displayDiscount = true;
                            break;
                        }
                    }
                }
            })
        },
        closeModal() {
            $('#orders-view-modal').modal('hide')
            this.$emit('modal-close');
        },
    },
    created() {
        this.getOrderInformation()
    }
}
</script>